@import url('https://fonts.googleapis.com/css2?family=Averia+Gruesa+Libre&display=swap');

/* Averia font */
@import url('https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

/* Im Fell font */
@import url('https://fonts.googleapis.com/css2?family=IM+Fell+English:ital@0;1&display=swap');

@font-face {
  font-family: "DutchMediaeval_Regular";
  src: url("../fonts/DutchMediaeval_Regular.eot");
  src: url("../fonts/DutchMediaeval_Regular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/DutchMediaeval_Regular.woff")format("woff"),
    url("../fonts/DutchMediaeval_Regular.woff2")format("woff2"),
    url("../fonts/DutchMediaeval_Regular.ttf")format("truetype"),
    url("../fonts/DutchMediaeval_Regular.svg")format("svg");
}

@font-face {
  font-family: "DutchMediaeval_Bold";
  src: url("../fonts/DutchMediaeval_Bold.eot");
  src: url("../fonts/DutchMediaeval_Bold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/DutchMediaeval_Bold.woff")format("woff"),
    url("../fonts/DutchMediaeval_Bold.woff2")format("woff2"),
    url("../fonts/DutchMediaeval_Bold.ttf")format("truetype"),
    url("../fonts/DutchMediaeval_Bold.svg")format("svg");
}

@font-face {
  font-family: 'Averia Serif Libre', cursive;

}

@font-face {
  font-family: 'IM Fell English', serif;

}


:root {
  --questcard: #2f445a;
  --primary: #0c293d;
  --yellow: #fecb36;
  --text-grey: #e2e7ff;
  --purple: #2c2156;
  --modalbtn: #046683;
  --gold: #f2a001;
  --text-light: #9093a0;
}

body {
  font-family: 'DutchMediaeval_Regular' !important;
  background: #0F1322 !important;
  overflow-x: hidden;
}

.homepage_inner {
  overflow: hidden;
}

.header .navbar {
  background: url('../images/header_menu_bg.png') !important;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  color: #fff;

}

.header .navbar.bannerstyle {
  background: none !important;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  color: #fff;

}

.header .navbar-nav a:hover {
  border-bottom: 1px solid var(--gold) !important;
}

.header .navbar-expand-lg.bannerstyle .navbar-collapse {
  gap: 15px;
}

.header .header_logo {
  width: 52px;
  height: 70px !important;
  position: absolute;
  top: 0px;
  transform: scale(1.5) translate(12.5%, 20.5%);
}

.header .walletbtn_sec {
  position: relative;
}

.header .walletbtn_sec {
  position: relative;
}

.header .wallet_addre {
  position: absolute;
  background: #2c2156;
  color: #fff;
  width: 170px;
  height: 40px;
  left: 0px;
  border-radius: 15px;
  margin-top: 10px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px 0 rgb(0 0 0/10%);
}

.header .wallet_addre p {
  font-size: 12px;
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.header .navbar .container {
  max-width: 1410px;
}

.homepage .training_sec .first_row {
  max-width: 1410px;
  margin: auto;
}

.header .navbar-expand-lg .navbar-collapse {
  justify-content: end;
}

.header .navbar-expand-lg .navbar-nav {
  gap: 20px;
}

.header .navbar-expand-lg .navbar-nav a {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  border-bottom: 1px solid transparent;

}

.header .navbar-nav a.active {
  border-bottom: 1px solid var(--gold) !important;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  height: 50px;
}

.header .social_icons {
  display: flex;
  gap: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.header .social_icons a {
  color: #fff !important;
  text-decoration: none;
}

.header .social_icons a:hover {
  color: var(--gold) !important;
}

.connect_btn {
  background: transparent !important;
  border: 1px solid var(--gold) !important;
  color: #fff !important;
  font-size: 12px !important;
  padding: 3px 12px !important;

}

.connect_btn:hover {
  background: var(--gold) !important;
  color: #fff !important;

}

.homepage .banner_sec {
  /* background: url("../images/banner1.png"); */
  /* background: url("../images/bannernewfire.png"); */
  /* background: url("../images/Cloudss.png"); */
  background: url("../images/banner_cloud.webp");

  background-size: 100% 100%;
  background-position: center;
  min-height: 100vh;
  object-fit: cover;
  position: relative;
  background-repeat: no-repeat;
  padding-left: 0px;
  padding-right: 0px;
  z-index: 6;
}

/* .homepage .banner_sec::after{
  content: "";
  background: url("../images/Towers.png");
} */
.quest_pages .inner_sec {
  min-height: 100vh;
}

.store_pages .inner_sec {
  min-height: 100vh !important;
}

/* .store_pages .inner_sec{
  height: 100vh;
} */
/* .homepage .banner_sec::after{
  content: "";
  background: url("../images/Towers.png");
  background-size: 100% 100%;
  background-position: center;
} */

.custom_container {
  max-width: 100% !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.homepage .banner_sec .banner_crown {
  width: 260px;
  /* padding-top: 30px; */
  padding-bottom: 20px;
  animation-name: crownani;
  /* animation-delay: .5s; */
  animation-duration: 2.5s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  transition: 0.5s ease
}

/* .homepage .training_sec .training_img {
  padding: 45px;
} */
@keyframes crownani {
  0% {

    transform-origin: top;
    transform: perspective(1000px) rotateX(20deg) scale(0.2);

  }

  100% {
    transform-origin: top;
    transform: perspective(1000px) rotateX(0deg) scale(1);
  }
}


.homepage .banner_sec .first_row {
  justify-content: center;
  position: relative;
  z-index: 3;
}

.homepage .banner_sec .fog_bg {
  position: absolute;
  background: url('../images/fog.png');
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}

.homepage .banner_sec .first_row .banner_rippon_sec p {
  margin-top: -20px;
  background: url(../images/rippon.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 400px;
  height: 150px;
  display: flex;
  align-items: center;
  margin: auto;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 25px;
  padding-left: 10px;
  padding-right: 10px;
}

.homepage .banner_btn {
  background: url(../images/banner_btn.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 10px;
  font-family: DutchMediaeval_Regular;
  font-size: 24px;
  color: #fff;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  width: 300px;
  transition: all 0.9s;
  transition-timing-function: cubic-bezier(0.5, 3, 0, 1);

}

.homepage .banner_btn:hover {
  transform: scale(1.05, 1.05);
  color: #fff;
}

.homepage .banner_sec .banner_bnt_sec {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.homepage .banner_sec .bottom_sec {
  height: 100px;
}


.homepage .banner_sec .left_img {
  position: absolute;
  bottom: 10px;
  width: 45%;
  left: 0px;
  z-index: 4;
  animation-name: moveInleft;
  animation-duration: 7s;
}

@keyframes moveInleft {
  0% {
    /* opacity: 0; */
    transform: translateX(-100px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    /* opacity: 1; */
    transform: translate(0);
  }
}


.questbottom {

  color: white;

}

.homepage .banner_sec .right_img {
  position: absolute;
  bottom: 10px;
  width: 45%;
  right: 0px;
  z-index: 4;
  animation-name: moveInRight;
  animation-duration: 7s;
}

@keyframes moveInRight {
  0% {
    /* opacity: 0; */
    transform: translateX(100px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    /* opacity: 1; */
    transform: translate(0);
  }

}

.homepage .banner_sec .grad_bg {

  bottom: -10px;
  height: 60px;
  position: absolute;
  width: 100%;
  z-index: 5;
  opacity: 1;
}

.homepage p {
  margin-bottom: 0px !important;
  font-size: 20px;
}

.homepage .second_sec .king_img {
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 100%;
  /* min-height: 100vh; */
  position: relative;
  top: 0px;
  z-index: 8;
}

.homepage .second_sec {
  position: relative;
  /* background: url('../images/new_img/newking.webp');
  min-height: 300px;
  background-size: cover; */
}

.homepage .second_sec .gradsec_img {
  position: absolute;
  bottom: -70px;
  z-index: 9;
  max-width: 100%;
  width: 100%;
}

.homepage .banner_sec .back_grad {
  background: linear-gradient(180deg, rgba(15, 19, 33, 0) 0%, #0F1322 32.63%, #0F1322 100%);
  height: 40px;

}

.homepage .third_desc_sec {
  /* background: url('../images/third.png'); */
  background-size: 100% 100%;
  margin-top: -20px;
  position: relative;
  z-index: 0;
  padding-bottom: 100px;
  background: #141121;
}

.savagesection {
  /* padding: 120px 40px 60px 40px; */
  width: 85%;
  margin: auto;
  padding: 150px 150px 35px 150px;
}

.homepage .allside {
  display: none;
}

.allside {
  width: 80px;
  height: 50px;
}

.savagesection .sec_title {
  font-family: IM FELL Double Pica SC;
  font-size: 70px;
  font-weight: 400;
  line-height: 62px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  margin-bottom: 40px;
}

.savagesection .sec_desc {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}

.homepage .third_desc_sec .content_sec {
  width: 75%;
  margin: auto;
  padding: 110px;
  text-align: center;
  color: #fff;
  font-family: DutchMediaeval_Regular;
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  height: 500px;

}

.homepage .third_desc_sec .content_sec .end_line {
  width: 200px;
}

.homepage .banner_sec .leftflag_sec {
  position: absolute;
  /* bottom: 0px; */
  bottom: 80px;
  width: 43%;
  z-index: 1;
  animation: tiltImage 5s linear infinite, moveInleft 3s;


}

.homepage .banner_sec .leftflag_sec1 {
  position: absolute;
  bottom: 0px;
  z-index: 1;
  animation: tiltImage 2s linear infinite, moveInleft 3s;
}

.homepage .banner_sec .rightflag_sec {
  position: absolute;
  bottom: 50px;
  width: 43%;
  z-index: 1;
  right: 0px;
  animation: tiltImage 5s linear infinite, moveInRight 3s;
}

.homepage .banner_sec .rightflag_sec1 {
  position: absolute;
  bottom: 40px;
  z-index: 1;
  right: 0px;
  animation: tiltImage 2s linear infinite, moveInRight 3s;
}

.homepage .patternsec .card_sec {
  padding: 15px;
  padding-bottom: 70px;
}

.homepage .charactor_sec {
  background: url('../images/new_img/bgbg.webp');
  background-size: 100% calc(100% + 250px);
  margin-top: -100px;
  z-index: -1;
  position: relative;
}

.homepage .charactor_sec::before {
  content: "";
  position: absolute;

  clip-path: polygon(0% 0%, 6.25% 8%, 12.5% 0%, 18.75% 8%, 25% 0%, 31.25% 8%, 37.5% 0%, 43.75% 8%, 50% 0%, 56.25% 8%, 62.5% 0%, 68.75% 8%, 75% 0%, 81.25% 8%, 87.5% 0%, 93.75% 8%, 100% 0%, 100% 100%, 93.75% 8%, 87.5% 100%, 81.25% 8%, 75% 100%, 68.75% 8%, 62.5% 100%, 56.25% 8%, 50% 100%, 43.75% 8%, 37.5% 100%, 31.25% 8%, 25% 100%, 18.75% 8%, 12.5% 100%, 6.25% 8%, 0 100%);

}

.homepage .charactor_sec .content_sec .img_sec {
  display: flex;
  justify-content: center;
}

/* .homepage  .charactor_sec   .content_sec .img_sec .char_img1{
  animation: bannermove 10.5s linear infinite;

} */
.inner_pages .inner_sec {
  padding-top: 100px;
  padding-bottom: 200px;
  height: 100%;
}

.inner_pages .top_sec .coin_sec .balance_coin {
  font-size: 20px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: end;
  color: #F2C80F;

}

.inner_pages .top_sec {
  width: 75%;
  margin: auto;
}

.inner_pages .top_sec .page_title {
  font-size: 35px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 5px;

}

.inner_pages .top_sec .desc_sec {
  position: relative;
}

.inner_pages .top_sec .coin_sec {
  position: absolute;
  right: -50px;
  bottom: -20px;

}

.inner_pages .tab_sec .tab-content {
  margin-top: 40px;
}

.inner_pages .top_sec .page_desc {
  font-size: 16px;
  font-weight: 200;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  width: 80%;
  margin: auto;

}

.inner_pages .tab_sec {
  width: 85%;
  margin: auto;
}

.inner_pages .tabs_design {
  justify-content: center;
  margin: auto;
  width: 100%;
  border-bottom: 1px solid #a5a5a5;
  gap: 50px;
}

.inner_pages .tabs_design .nav-link {
  border: none !important;
}

.inner_pages .tabs_design .nav-item {
  border-bottom: 1px solid #4B4B4B;
}

.inner_pages .tabs_design .nav-item .nav-link {

  padding-left: 50px;
  padding-right: 50px;

}

.inner_pages .tabs_design .nav-link {
  color: var(--text-light);
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;

}

.inner_pages .tabs_design .nav-link.active {
  color: #F2C80F;
  background: transparent;
  border-bottom: 2px solid #F2C80F !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;

}

.inner_pages .tabs_design .nav-link:hover {
  color: #F2C80F;
  background: transparent;
  border: none;
  border-bottom: 2px solid #F2C80F;
}

.inner_pages .tabs_design .nav-link:focus,
.inner_pages .tabs_design .nav-link:hover {
  border: none !important;
  border-bottom: 2px solid #F2C80F !important;
}

.inner_pages .quest_card {
  background: url('../images/quest_card.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 25px;
  padding-bottom: 70px;
  position: relative;
  margin-bottom: 50px;
  margin-top: 50px;
  min-width: 550px;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;

}

.inner_pages .quest_card.active_card {
  background: url('../images/active_card.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 25px;
  padding-bottom: 70px;
  position: relative;
  margin-bottom: 50px;
  margin-top: 50px;
  min-width: 550px;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 55px;

}

.inner_pages .quest_card.active_card .time_duration {
  position: absolute;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-48.5%, -21%);
  top: 14px;
  color: #F2C80F;

}

.inner_pages .quest_card .quest_img {
  min-width: 250px;
  max-width: 250px;
  min-height: 250px;
  max-height: 250px;

}

.inner_pages .card_btn {
  background: url('../images/connect-wallet.png'), #202850;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #f2c80f;
  padding: 5px 15px 5px 15px;
}

.inner_pages .card_btn:hover {

  color: #fff;
}

.inner_pages .quest_card .card_btn {
  position: absolute;
  bottom: 0px;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-41.5%, 20%);
  min-width: 25%;
  max-width: 25%;
  height: 50px;
}

.inner_pages .quest_card .rightside .card_title {
  color: #F2C80F !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
}

.inner_pages .quest_card .quest_row {
  align-items: center;
}

.trained {
  position: absolute;
  width: 70%;
  bottom: -10px;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0%);
}

@media only screen and (max-width: 450px) {
  .trained {
    display: none;
  }
}

@media only screen and (min-width: 4000px) {
  .homepage .banner_sec .banner_crown {
    top: -200px !important;
  }
}

@media only screen and (min-width: 2800px) {
  .trained {
    width: 60%;
  }
}

.inner_pages .quest_card .rightside .lists {
  color: #F2C80F;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
}

.inner_pages .quest_card .rightside .lists span {
  color: #fff;
  margin-left: 5px;
}

@keyframes bannermove {
  0% {
    margin-left: -2125px;

  }

  50% {
    margin-left: -1000px;
  }

  100% {
    margin-left: 0px;
  }

}

.homepage .charactor_sec .marquee_sec {
  padding-bottom: 25%;
}

.btn.connect-wallet {
  background: url('../images/connect-wallet.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: #F2C80F;
  font-family: DutchMediaeval_Regular;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  transition: all 0.9s;
  transition-timing-function: cubic-bezier(0.5, 3, 0, 1);

}

.btn.connect-wallet:hover {
  color: #fff;
  transform: scale(1.05, 1.05);
}

.footer {
  background: #0F1322;
  padding: 20px;
}

.footer .left_sec p {
  color: #fff;
  margin-bottom: 0px;
}

.footer .center_sec_list {
  display: flex;
  gap: 20px;
}

.footer .center_sec_list a {
  color: #fff;
  text-decoration: none;
}

.footer .center_sec_list {
  color: #fff;
}

.footer .center_sec_list a:hover {
  color: #f2c80f;
}

.footer .end_sec .social_icons {
  display: flex;
  gap: 20px;
  font-size: 24px;
  color: #fff;
  justify-content: center;
}

.footer .end_sec .social_icons a {
  color: #fff;
  text-decoration: none;

}

.footer .end_sec .social_icons svg:hover {
  color: #F2C80F;
  cursor: pointer;
}



.homepage .faq_sec .accordion-button[aria-expanded="false"]::after {
  background: url('../images/plus.png');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.homepage .faq_sec .accordion-button[aria-expanded="true"]::after {
  background: url('../images/minus.png');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

@keyframes tiltImage {
  0% {
    transform: skewY(5deg);
  }

  50% {
    transform: skewY(-5deg);
  }

  100% {
    transform: skewY(5deg);
  }
}

.homepage .patternsec {
  /* background: url(../images/new_img/patternbgnew.png); */
  /* background: url(../images/new_img/patternbgnew1.webp); */
  background-size: 60% 100%;
  /* background-size: 100% 100%; */
  /* background: #1a1426; */
  background-repeat: repeat-x;
  margin-top: -300px;
  position: relative;




}

.homepage .patternsec .story-pattern {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  top: 10%;
  left: 0;
  background: rgb(14, 13, 29);
  background: linear-gradient(135deg, rgba(14, 13, 29, 1) 0%, #1a1426 100%);
  overflow: none;
  clip-path: polygon(0% 0%, 6.25% 8%, 12.5% 0%, 18.75% 8%, 25% 0%, 31.25% 8%, 37.5% 0%, 43.75% 8%, 50% 0%, 56.25% 8%, 62.5% 0%, 68.75% 8%, 75% 0%, 81.25% 8%, 87.5% 0%, 93.75% 8%, 100% 0%, 100% 100%, 93.75% 92%, 87.5% 100%, 81.25% 92%, 75% 100%, 68.75% 92%, 62.5% 100%, 56.25% 92%, 50% 100%, 43.75% 92%, 37.5% 100%, 31.25% 92%, 25% 100%, 18.75% 92%, 12.5% 100%, 6.25% 92%, 0 100%);
}

.homepage .patternsec .story-pattern::before {
  position: absolute;
  content: "";
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background: url(../images/new_img/patternsmall.webp);
  background-size: 800px auto;
  background-position: center center;
  mix-blend-mode: overlay;
}

.homepage .design_card {
  width: 73%;
  background: url(../images/card_bg.webp);
  background-size: 100% 100%;
  /* padding: 80px; */
  padding: 150px;
  color: #fff;
  margin: auto;
  font-family: DutchMediaeval_Regular;
  font-size: 20px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 70px;
  padding-right: 70px;
  position: relative;
  padding-bottom: 115px;

}

.homepage .charactor_sec .content_sec {
  text-align: center;
  width: 85%;
  margin: auto;
  padding: 150px;
}

.homepage .charactor_sec .content_sec .sec_title {
  font-family: IM FELL Double Pica SC;
  font-size: 70px;
  font-weight: 400;
  line-height: 62px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  margin-top: 80px !important;
  margin-bottom: 20px !important;

}

.homepage .charactor_sec .content_sec .sec_desc {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;

}

.homepage .charactor_sec .content_sec .end_line {
  width: 200px;
}

.homepage .charactor_sec .marquee_sec .chr_img {
  height: 370px;
  width: 300px;
  object-fit: cover;
  border-radius: 10px;
  margin: 15px;
}

.homepage .training_sec {
  vertical-align: middle;
}

.homepage .training_sec .rightsec .sec_title {
  font-family: IM FELL Double Pica SC;
  font-size: 70px;
  font-weight: 400;
  line-height: 62px;
  letter-spacing: 0em;
  text-align: Left;
  color: #fff;

}

.homepage .training_sec .first_row {
  align-items: center;
  margin-top: 50px;
}

.homepage .training_sec .rightsec .sec_desc {
  font-size: 22px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;

}

.homepage .training_sec {
  align-items: center;
  padding-bottom: 40px;
}

.homepage .training_sec .end_line {
  width: 200px;
  margin: auto;
  display: flex;
}

.homepage .roadmap_sec .top_sec {
  text-align: center;
}

.homepage .roadmap_sec .top_sec .title {
  font-size: 55px;
  font-weight: 400;
  line-height: 62px;
  letter-spacing: 0em;
  text-align: center;
  color: #F2C80F;
  margin-bottom: 40px;

}

.homepage .faq_sec .container {
  max-width: 90%;
}

.inner_container {
  max-width: 90% !important;
}

/* .homepage .charactor_sec .inner_container {
  max-width: 100% !important;
} */

.homepage .faq_sec .left_sec .sec_title {
  color: #F2C80F;
  font-size: 55px;
  font-weight: 400;
  line-height: 84px;
  letter-spacing: 0em;
  text-align: left;

}

.homepage .faq_sec .accordion-button {
  background: #151725;
  color: #fff;
  border: none !important;
}

.homepage .faq_sec .accordion-button:focus {
  box-shadow: none;
}

.homepage .faq_sec .accordion-item {
  border: none !important;
  box-shadow: none !important;
  margin-bottom: 20px;
}

.homepage .faq_sec .accordion-body {
  background: #151725;
  color: #fff;
  border: none !important;
  font-family: "DutchMediaeval_Regular";
}

.homepage .banner_sec .anim_fog {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  Left: 0;
  top: 0;
  background: url('../images/fogss.png') repeat-x;
  background-size: 200% auto;
  background-position: bottom;
  animation: fogAnim 10s;
  animation-timing-function: Linear;
  animation-iteration-count: infinite;
}

@keyframes fogAnim {
  0% {
    background-position: -200% bottom
  }

  100% {
    background-position: 0% bottom
  }
}

.homepage .faq_sec {
  padding-top: 30px;
  padding-bottom: 30px;
}

.homepage .team_sec .team_card {
  position: relative;
}

.homepage .team_sec .team_card .team_card_bg {
  position: absolute;
  background: url(../images/storecard.png);
  background-size: 100% 100%;
  z-index: 1;
  width: 100%;
  height: 400px;
}

.homepage .team_sec .team_card {
  min-height: 400px;
  max-height: 400px;
  /* min-width: 270px;
    max-width: 270px; */
}

.homepage .team_sec .team_card .team_img {
  min-height: 335px;
  max-height: 335px;
  margin-top: 25px;
  width: 100%;

  object-fit: cover;
}

.homepage .team_sec .title_top {
  text-align: center;
  padding-bottom: 40px;
  margin-top: 40px;
}

.homepage .team_sec .title_top .sec_title {
  font-size: 55px;
  font-weight: 400;
  line-height: 84px;
  letter-spacing: 0em;
  text-align: center;
  color: #F2C80F;

}

.homepage .team_sec .card_sec {
  margin-bottom: 40px;
}

.homepage .team_sec .end_line_sec {
  text-align: center;
  margin-bottom: 20px;
}

/* .homepage .tower_sec{
    background: url(../images/pagetower.png);
    background-size: 100% 100%;
    
  } */
.homepage .tower_sec .inner_container {
  max-width: 100% !important;
}

.homepage .tower_sec .content_sec .leftside .sec_title {
  font-size: 35px;
  font-weight: 400;
  line-height: 63px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  font-family: "DutchMediaeval_Bold";

}

.homepage .tower_sec .content_sec .leftside .sec_desc {
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;

}

.homepage .faq_sec .left_sec .faq_img {
  position: relative;
}

.homepage .faq_sec .left_sec .faq_img::after {
  content: "";
  background: radial-gradient(circle, rgba(240, 207, 48, 0.8225884103641457) 0%, rgba(240, 204, 48, 0) 71%);
  height: 150px;
  width: 150px;
  animation: zoom-in-zoom-out 3s ease infinite;
  position: absolute;
  right: 4px;
  bottom: -10px;
  opacity: 0.5;
}


.homepage .card_sec_glow .glow_sec {
  position: relative;
  text-align: center;
}

/* .homepage .faq_sec .left_sec .glow_sec{
    animation: gloow 3s infinite alternate
  } */
@keyframes gloow {
  from {
    background: #f2c80f54;
    box-shadow: 0 0 10px -10px #F2C80F;
  }

  to {
    background: #F2C80F;
    box-shadow: 0 0 10px 10px #F2C80F;
  }
}

.homepage .card_sec_glow .img_glow {
  background: radial-gradient(circle, rgba(240, 207, 48, 0.8225884103641457) 0%, rgba(240, 204, 48, 0) 71%);
  height: 300px;
  width: 300px;
  animation: zoom-in-zoom-out 3s ease infinite;
  position: absolute;
  right: 19%;
  bottom: -60px;
  opacity: 0.5;

}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes glowing3 {
  0% {
    background-color: #bc881b;
    ;
    box-shadow: 0 0 50px #bc881b;
  }

  50% {
    background-color: #bc881b;
    box-shadow: 0 0 100px #db990e;
  }

  100% {
    background-color: #bc881b;
    box-shadow: 0 0 50px #e59f0a;
  }
}

@keyframes animateglow {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.3;
  }
}

.homepage .tower_sec .bottom_sec {
  position: relative;
}

/* .homepage .tower_sec .bottom_sec .content_sec {
  position: sticky;
  top: 0px;

}  */

.homepage .tower_sec .bottom_sec .content_sec .leftside {
  /* position: absolute; */
  /* top: 65px; */
  width: 50%;
  padding: 80px;
}

.homepage .roadmap_sec .mapsec .sec_title {
  font-size: 24px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;

}

.homepage .roadmap_sec .mapsec .sec_desc {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin-bottom: 100px;

}

.homepage .roadmap_sec .full_map {
  background: url(../images/whiteroadmap.webp);
  /* background-size: 100% 90%; */
  background-size: 1024px;
  background-position: center;

  background-repeat: no-repeat;
}

.homepage .roadmap_sec .top_sec .first_row {
  margin-bottom: 50px;
  padding-top: 70px;
}

.homepage .roadmap_sec .top_sec .second_row {
  height: 800px;
  padding-top: 20%;
  padding-right: 55px;
}

.homepage .roadmap_sec .top_sec .third_row {
  padding-bottom: 10%;
  padding-left: 100px;
}

.homepage .roadmap_sec .down_sec .first_row {
  padding-bottom: 20%;

}

.loadmore_btn {
  border: 1px solid var(--gold) !important;
  /* background: url('../images/loadmore.png');
  background-size: 100% 100%;
  background-repeat: no-repeat; */
  padding: 5px 25px 5px 25px !important;
  color: #fff !important;
  font-size: 12px !important;
}

.loadmore_btn:hover {
  color: #fff !important;
  background: var(--gold) !important;

}

.quest_pages .loadmore_btn_sec {
  text-align: center;
}

.mining_pages .mint_form .loadmore_btn {
  height: 50px;
  width: 135px;
}

.form-control {
  border: 1px solid #F5F5F5 !important;
  background: transparent !important;
  height: 45px;
  border-radius: 10px !important;
  color: #fff !important;
}

.form-control::placeholder {
  color: #757575 !important;
}

.quest_pages .form_sec label {
  color: #fff;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;

}

.inner_pages .mint_form {
  padding-top: 100px;
}

.btn.btn-minting {
  background: url('../images/connect-wallet.png'), #2B335D;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 12px 25px 12px 25px;
  color:
    #F2C80F;
  font-size: 12px;
  min-width: 100px;
}

.btn.btn-minting:hover {
  color: #fff;
}

.mining_pages .btn_sec {
  text-align: center;
  padding-top: 50px;
}

.mining_pages .mint_form .upload_title {
  font-size: 22px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin-bottom: 5px;
}

.mining_pages .mint_form .upload_type {
  font-size: 16px;
  font-weight: 200;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;

}

.mining_pages .mint_form .leftsec .upload_frame {
  background: linear-gradient(0deg, #2B335D, #2B335D),
    linear-gradient(0deg, #858585, #858585);
  border: 1px solid #858585;
  text-align: center;
  color: #fff;
  padding: 25px;
  border-radius: 5px;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.mining_pages .mint_form .leftsec .upload_frame .file_upload_img {
  margin-bottom: 10px;
}

.mining_pages .mint_form .leftsec .upload_frame .first {

  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;

}

.mining_pages .mint_form .leftsec .upload_frame .second {

  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  margin-bottom: 0px;

}

.mining_pages .mint_form .leftsec .upload_frame .third {
  font-size: 14px;
  font-weight: 200;
  line-height: 29px;
  letter-spacing: 0em;
  color: #AEAEAE;

}

.form_sec .upload_row {
  align-items: end;
}

.form-control:focus {
  box-shadow: none;
}

.quest_pages .quest_rippon_sec {
  background: url(../images/quest_rippon.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 70px;
  margin: auto;

}

.quest_pages .quest_rippon_sec p {
  font-size: 28px;
  margin-bottom: 0px;
  margin-top: 5px;
}





.quest_pages .quest_char_card {
  position: relative;
  min-width: 330px;
  max-width: 330px;
  max-height: 430px;
  min-height: 430px;
  margin: auto;
}

.quest_pages .quest_char_card .quest_char_img {
  min-width: 330px;
  max-width: 330px;
  max-height: 400px;
  min-height: 400px;
  object-fit: cover;
  padding: 8px;
}

.quest_pages .quest_char_card .quest_dframe {
  position: absolute;
  background: url('../images/quest_char_card.png');
  background-size: 100% 100%;
  height: 100%;
  width: 100%;

}

.quest_pages .quest_char_card .btn_sec .card_btn {
  position: absolute;
  z-index: 3;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-48.5%, -21%);
  left: 50%;
  height: 50px;
  padding: 5px 30px 5px 30px;
}


.btn-menu {
  background: transparent !important;
}

/* fog */
.fogwrapper {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
  filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
  overflow-x: hidden;
}

#foglayer_01,
#foglayer_02,
#foglayer_03 {
  height: 100%;
  position: absolute;
  width: 200%;
}

#foglayer_01 .image01,
#foglayer_01 .image02,
#foglayer_02 .image01,
#foglayer_02 .image02,
#foglayer_03 .image01,
#foglayer_03 .image02 {
  float: left;
  height: 100%;
  width: 50%;
}

#foglayer_01 {
  -webkit-animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
  -moz-animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
  animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
}

#foglayer_02,
#foglayer_03 {
  -webkit-animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
  -moz-animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
  animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
}

/* ---------- Moving Fog ---------- */
/*
    'size: cover' || 'size: 100%'; results remain the same
    'attachment: scroll' can be added or removed; results remain the same
    'attachment: fixed' causing unexpected results in Chrome
    'repeat-x' || 'no-repeat'; results remain the same
  */
#foglayer_01 .image01,
#foglayer_01 .image02 {
  background: url("https://raw.githubusercontent.com/danielstuart14/CSS_FOG_ANIMATION/master/fog1.png") center center/cover no-repeat transparent;
}

#foglayer_02 .image01,
#foglayer_02 .image02,
#foglayer_03 .image01,
#foglayer_03 .image02 {
  background: url("https://raw.githubusercontent.com/danielstuart14/CSS_FOG_ANIMATION/master/fog2.png") center center/cover no-repeat transparent;
}

/* ---------- Keyframe Layer 1 ---------- */
@-webkit-keyframes foglayer_01_opacity {
  0% {
    opacity: .1;
  }

  22% {
    opacity: .5;
  }

  40% {
    opacity: .28;
  }

  58% {
    opacity: .4;
  }

  80% {
    opacity: .16;
  }

  100% {
    opacity: .1;
  }
}

@-moz-keyframes foglayer_01_opacity {
  0% {
    opacity: .1;
  }

  22% {
    opacity: .5;
  }

  40% {
    opacity: .28;
  }

  58% {
    opacity: .4;
  }

  80% {
    opacity: .16;
  }

  100% {
    opacity: .1;
  }
}

@-o-keyframes foglayer_01_opacity {
  0% {
    opacity: .1;
  }

  22% {
    opacity: .5;
  }

  40% {
    opacity: .28;
  }

  58% {
    opacity: .4;
  }

  80% {
    opacity: .16;
  }

  100% {
    opacity: .1;
  }
}

@keyframes foglayer_01_opacity {
  0% {
    opacity: .1;
  }

  22% {
    opacity: .5;
  }

  40% {
    opacity: .28;
  }

  58% {
    opacity: .4;
  }

  80% {
    opacity: .16;
  }

  100% {
    opacity: .1;
  }
}

/* ---------- Keyframe Layer 2 ---------- */
@-webkit-keyframes foglayer_02_opacity {
  0% {
    opacity: .5;
  }

  25% {
    opacity: .2;
  }

  50% {
    opacity: .1;
  }

  80% {
    opacity: .3;
  }

  100% {
    opacity: .5;
  }
}

@-moz-keyframes foglayer_02_opacity {
  0% {
    opacity: .5;
  }

  25% {
    opacity: .2;
  }

  50% {
    opacity: .1;
  }

  80% {
    opacity: .3;
  }

  100% {
    opacity: .5;
  }
}

@-o-keyframes foglayer_02_opacity {
  0% {
    opacity: .5;
  }

  25% {
    opacity: .2;
  }

  50% {
    opacity: .1;
  }

  80% {
    opacity: .3;
  }

  100% {
    opacity: .5;
  }
}

@keyframes foglayer_02_opacity {
  0% {
    opacity: .5;
  }

  25% {
    opacity: .2;
  }

  50% {
    opacity: .1;
  }

  80% {
    opacity: .3;
  }

  100% {
    opacity: .5;
  }
}

/* ---------- Keyframe Layer 3 ---------- */
@-webkit-keyframes foglayer_03_opacity {
  0% {
    opacity: .8
  }

  27% {
    opacity: .2;
  }

  52% {
    opacity: .6;
  }

  68% {
    opacity: .3;
  }

  100% {
    opacity: .8;
  }
}

@-moz-keyframes foglayer_03_opacity {
  0% {
    opacity: .8
  }

  27% {
    opacity: .2;
  }

  52% {
    opacity: .6;
  }

  68% {
    opacity: .3;
  }

  100% {
    opacity: .8;
  }
}

@-o-keyframes foglayer_03_opacity {
  0% {
    opacity: .8
  }

  27% {
    opacity: .2;
  }

  52% {
    opacity: .6;
  }

  68% {
    opacity: .3;
  }

  100% {
    opacity: .8;
  }
}

@keyframes foglayer_03_opacity {
  0% {
    opacity: .8;
  }

  27% {
    opacity: .2;
  }

  52% {
    opacity: .6;
  }

  68% {
    opacity: .3;
  }

  100% {
    opacity: .8;
  }
}

/* ---------- Keyframe moveMe ---------- */
@-webkit-keyframes foglayer_moveme {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

@-moz-keyframes foglayer_moveme {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

@-o-keyframes foglayer_moveme {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

@keyframes foglayer_moveme {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

@media only screen and (min-width: 280px) and (max-width: 767px) {

  #foglayer_01 .image01,
  #foglayer_01 .image02,
  #foglayer_02 .image01,
  #foglayer_02 .image02,
  #foglayer_03 .image01,
  #foglayer_03 .image02 {
    width: 100%;
  }
}

/* fog */
@media only screen and (max-width:575px) {
  .store_line_sec img {
    display: none;
  }

  .quest_pages .quest_char_card {
    min-width: unset;
    max-width: unset;
    width: 100%;

  }

  .quest_pages .quest_char_card .quest_char_img {
    min-width: unset;
    max-width: unset;
    width: 100%;

  }

  .quest_pages .quest_char_card .btn_sec .card_btn {
    font-size: 12px;
  }

  .homepage .banner_sec {
    /* background: url("../images/bannernewfire.png"); */
    background-size: cover;
    background-position: center;

  }



  .homepage .banner_sec .left_img,
  .homepage .banner_sec .right_img {
    width: 53%;
    /* height: 125px; */
    bottom: 0px;
  }

  .homepage .banner_sec .grad_bg {
    bottom: -30px;
  }

  .homepage .banner_sec .leftflag_sec,
  .homepage .banner_sec .rightflag_sec {
    bottom: 75px;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 35px;
  }

  .store_pages .store_card_sec {
    margin-top: 0px !important;
  }

  .store_pages .store_line_sec .frameborderstyle.bottom {
    margin-top: -70px !important;
  }
}

@media only screen and (max-width:767px) {

  .inner_pages .quest_card {
    min-width: unset;
    max-width: unset;
    width: 100%;
  }

  .store_pages .store_card_sec {
    margin-top: 0px !important;
  }

  .store_pages .store_line_sec .frameborderstyle.bottom {
    margin-top: -70px !important;
  }

}

@media only screen and (max-width:991px) {
  .inner_pages .top_sec .page_title {
    font-size: 30px;
  }

  .inner_pages .quest_card {
    text-align: center;
  }

}

@media only screen and (max-width:1199px) {
  .inner_pages .tabs_design {
    gap: 20px;
  }

  .quest_pages .inner_sec {
    height: unset;
  }

  .store_pages .inner_sec {
    height: unset;
  }
}

@media only screen and (min-width:1200px) and (max-width:1350px) {
  .inner_pages .quest_card {
    min-width: 525px;
    max-width: 525px;
  }

  .store_pages .store_card_sec {
    padding: 100px 45px 80px 45px !important;
  }

  .homepage .second_sec .gradsec_img {
    bottom: -60px;
  }

  .homepage .third_desc_sec .content_sec {
    width: 88%;
    padding-left: 90px;
    padding-right: 98px;
  }

  .homepage .training_sec .rightsec .sec_desc {
    width: 85% !important;
  }

  .homepage .charactor_sec .content_sec {
    padding: 160px 116px;
  }
}

@media only screen and (min-width:1350px) and (max-width:1400px) {
  .homepage .third_desc_sec .content_sec {
    width: 88%;
  }

  .homepage .charactor_sec .content_sec {
    text-align: center;
    width: 90%;
  }
}

.reveal_bg {
  background: url(../images/unreveal_bg.png);
  background-size: 100% 100%;
  position: relative;
  bottom: 90px;
  /* position: absolute; */
  z-index: 3;
  left: 0;
  width: 100%;
  height: 125px;
  padding: 5px 30px 5px 30px;
  /* bottom: -10px; */
}

.text_reveal_head {
  color: #0A0A0A;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
}

.icon_reveal {
  max-width: 20px;
  max-height: 20px;
}

.icon_tetx_reveal {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.textgreen_reveal {
  font-size: 14px;
  color: #1A9806;
  font-weight: 600;

}

.text_reveal {
  font-size: 14px;
  font-weight: 600;

  color: #0A0A0A;

}

.reveal_text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -20%);
}

.overlay_icon {
  position: absolute;
  right: 20px;
  top: 20px;
  max-width: 40px;
}

.tooltip_des {
  background-color: #fff;
  border-radius: 30px;
  opacity: 1 !important;
}

#tooltip>.tooltip-inner {
  background-color: #fff;
  background: #fff;
  color: #000;
  border-radius: 30px;
  min-width: 280px;
  padding: 10px 0px;
}

#tooltip>.tooltip-arrow::before {
  border-top-color: #fff;
}



.quest_pages .quest_char_card.store_bg_card .quest_dframe {
  position: absolute;
  background: url('../images/store_card_bg.png');
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
}

.quest_pages .quest_char_card.store_bg_card .quest_char_img {
  margin-top: 10px;
}

.reveal_bg.reveal_bg_store {
  background: url(../images/store_bg_text.png);
  bottom: 115px;
  /* position: absolute; */
  left: 13px;
  width: calc(100% - 46px);
  height: 100px;
  /* bottom: -10px; */
  margin: 8px;
  border-radius: 0px 0px 10px 10px;

}

.reveal_bg.reveal_bg_store .reveal_text {
  transform: translate(-50%, -50%) !important;
}

/* .quest_pages .quest_char_card.store_bg_card
{
min-height: 400px !important;
max-height: 400px !important;
} */

.quest_pages .quest_char_card.store_bg_card .quest_char_img {
  min-height: 375px !important;
  max-height: 375px !important;
}

.quest_pages .quest_char_card.store_bg_card .btn_sec .card_btn {
  transform: translate(-48.5%, -33%) !important;
  z-index: 4;
  min-width: 130px !important;
}

.store_title_pos {
  position: absolute;
  color: #fff;
  top: 20px;
  left: 50%;
  transform: translate(-50%, -3px);
}

.page_privacy_bg {
  background: url(../images/privacy_pattern.png);
  background-size: cover;

}

.privacy_text_white {
  color: #fff;
  font-size: 13px !important;
}



.card.quest_card_style {
  background: var(--questcard);
  border-radius: 20px;
}

.card.quest_card_style .first_row {
  padding: 20px;
  align-items: center;
}

.card.quest_card_style .first_row .rightside .title_card {
  color: #fff;
  font-size: 18px;
}

.card.quest_card_style .first_row .rightside .catg {
  color: #fff;
  font-size: 14px;
  margin-bottom: 5px;
}

.card.quest_card_style .first_row .rightside .count {
  color: #fff;
  font-size: 12px;
  margin-bottom: 5px;
}

.card.quest_card_style .first_row .rightside .count img {
  margin-right: 5px;
}

.card.quest_card_style .first_row .rightside .token_label {
  background: var(--yellow);
  padding: 5px;
  color: #000;
  border-radius: 5px;
  text-align: center;
  width: 70%;
  margin-top: 20px;

}

/* .card.quest_card_style .first_row .leftside .img_sec{
  min-width: 180px;
  max-width: 180px;
  min-height: 180px;
  max-height: 180px;
  background: #000;
  padding: 10px;
} */
.card.quest_card_style .first_row .leftside .quest_pic {
  min-width: 180px;
  max-width: 180px;
  min-height: 180px;
  max-height: 180px;
  object-fit: cover;
  border-radius: 25px;
  border: 8px solid #000;
}

.card.quest_card_style .bottom_link {
  background-color: var(--primary);
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 10px;
  text-align: center;
}

.card.quest_card_style .bottom_link a {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
}

.card.quest_card_style .first_row .rightside .time_duration {
  background: rgb(238, 0, 147);
  padding: 5px;
  padding-top: 0PX;
  padding-bottom: 0px;
  color: #fff;
  font-size: 14PX;
  width: 30%;
  text-align: center;
  border-radius: 5PX;
}

.card.quest_card_style .bottom_link .final_link {
  color: var(--yellow);
}

.card.char_card_style {
  background: transparent;
  border-radius: 20px;
  min-height: 400px;
  max-height: 400px;
  width: 100%;
  position: relative;
}

.card.folder_card {
  min-height: 400px;
  max-height: 400px;
  width: 100%;
  position: relative;
  background: url(../images/new_img/folder_card.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.card.folder_card .name {
  text-emphasis: none;
  color: #fff;
  text-align: center;
  padding-top: 10px;
  font-size: 18px;
}

.card.folder_card .bottom_det_sec {
  position: absolute;
  bottom: 20px;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.card.folder_card .bottom_det_sec .label_card {
  color: #f2c80f;
  font-size: 18px;
  text-align: center;
  margin-bottom: 7px;
}

.card.folder_card .bottom_det_sec .label_value {
  color: #fff;
  font-size: 16px;
  text-align: center;
  margin-bottom: 0px;
}

.card.folder_card .inner_card_sec {
  min-height: 400px;
  max-height: 400px;

}

.card.salelater_card .sale_check_sec {
  position: absolute;
  top: 10px;
  right: 10px;

}

.card.salelater_card .sale_check_sec .form-check-input {
  height: 20px;
  width: 20px;
  box-shadow: none !important;
}

.card.salelater_card .sale_check_sec .form-check-input:checked {
  background-color: #f2a001 !important;
  border: 1px solid #f2a001 !important;


}

.card.char_card_style .char_img {
  min-height: 400px;
  max-height: 400px;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;

}

.card.char_card_style .detail_sec {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  bottom: 22px;
  z-index: 2;
}

.card.char_card_style .detail_sec .title {
  color: var(--yellow);
  font-size: 8px;
  margin-bottom: 0px;
  text-align: center;
}

.card.char_card_style .detail_sec .title_desc {
  color: #fff;
  font-size: 12px;
  margin-bottom: 0px;
  text-align: center;
}

.card.char_card_style .reveal_sec {
  position: absolute;
  width: 80%;
  bottom: 30px;
  text-align: center;
  background: var(--yellow);
  border-radius: 6px;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 4px;
  /* box-shadow: 0 0 10px 5px var(--yellow),
 0 0 10px 7px var(--yellow),
 0 0 15px 10px var(--yellow),
 0 0 10px 15px var(--yellow); */

}

.card.char_card_style .reveal_sec .reveal_btn {
  color: #000;
  text-decoration: none;
  text-align: center;

}

.quest_detail .recent_quest {
  margin-top: 100px;
}

.quest_detail .recent_quest .page_title {
  color: #fff;
}

.quest_detail .recent_quest .card_rows {
  display: flex;
  justify-content: center;

}

.card.store_card_style {
  background: transparent;
  position: relative;
  min-height: 400px;
  max-height: 400px;
  width: 100%;
}

.card.store_card_style .store_frame {
  background: url('../images/storecard.png');
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
  z-index: 3;
}

.card.store_card_style .title {
  position: absolute;
  color: #fff;
  top: 25px;
  z-index: 4;
  transform: translate(-50%, -50%);
  left: 50%;
}

.card.store_card_style .desc {
  color: #fff;
  position: absolute;
  z-index: 4;
  transform: translate(-50%, -50%);
  left: 50%;
  bottom: 10%;
  font-size: 10px;
}

.card.store_card_style .price {
  color: #fff;
  position: absolute;
  z-index: 4;
  transform: translate(-50%, -50%);
  left: 50%;
  bottom: -1%;
  margin-bottom: 5px;
  font-size: 15px;
}

.card.store_card_style .store_img {
  min-height: 310px;
  max-height: 310px;
  object-fit: cover;
  width: 100%;
  margin-top: 50px;
}

.card.store_card_style .price .store_coin {
  margin-right: 10px;

}

.store_pages .store_card_sec {
  width: 85%;
  margin: auto;
  margin-top: 4%;
  padding: 90px 35px 60px 35px;
  position: relative;
  z-index: 6;
  /* margin-top: -80px; */
}

.rightborder {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 35%;
}

.brightborder {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: rotateX(180deg);
  max-width: 35%;
}

.leftborder {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 35%;
}

.bleftborder {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: rotateX(180deg);
  max-width: 35%;
}

.store_pages .store_line_sec {
  width: 100%;
  text-align: center;
  margin: auto;
  margin-top: 10px;
}

.store_pages .store_line_sec .frameborderstyle {
  width: 100%;
}

.store_pages .store_line_sec .frameborderstyle.bottom {
  margin-top: -100px;
}

.quest_detailcard_style .rightside .det_title {
  color: var(--yellow);
  margin-bottom: 5px;
}

.quest_detailcard_style .rightside .det_desc {
  color: #fff;
  margin-bottom: 5px;
}

.card.quest_detailcard_style .first_row .leftside .quest_pic {
  min-width: 320px;
  max-width: 320px;
  min-height: 320px;
  max-height: 320px;

}

.card.quest_detailcard_style .first_row .leftside .img_sec {
  min-width: 320px;
  max-width: 320px;
  min-height: 320px;
  max-height: 320px;
  margin: auto;
}

.detail_card_sec {
  width: 80%;
  margin: auto;
}

.card.quest_detailcard_style .first_row .rightside .title_card {
  font-size: 16px;
}

.card.quest_detailcard_style .first_row .rightside .token_label {
  width: 45%;
}

.card.char_card_style.create_card .create_img {
  min-height: 430px;
  max-height: 430px;
  object-fit: cover;
  border-radius: 15px;
  width: 100%;
}

.card.char_card_style.create_card {
  min-height: 430px;
  max-height: 430px;
  border: none;
}
.card.char_card_style.create_card.detail_create_card .detail_sec {
bottom: 0px;
}
.card.char_card_style.create_card.detail_create_card .shadow_blur {
  bottom: 0px;
  }
.card.char_card_style.create_card .btn_sec {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.quest {
  color: #F5F5F5;
  cursor: pointer;
}

.card.char_card_style.create_card .create_desc {
  position: absolute;
  bottom: 13px;
  left: 149px;
  color: #fff;
  z-index: 2;
}

.quest_pages,
.store_pages {
  background: url('../images/quest_bg.png');
  /* background-image: url('../images/new_img/m_bgall.png'); */
  height: 100%;
  width: 100%;
  /* background-size: 945px 1230px; */
  background-size: contain;
}

.modal-dialog .modal-content {
  background: var(--purple);
  border: 1px solid #a8a8a8;
}

.modal-dialog .modal-content .modal-header {
  border-bottom: none;
}

.modal-dialog .modal-content .bg_pattern {
  background: url('../images/modal_pattern.png');
  height: 100%;
  width: 100%;
  background-size: 100% 100px;
  position: absolute;
  background-repeat: no-repeat;
  top: 5px;
}

.modal-dialog .modal-content .modal-header .modal-title {
  color: #fff;
  text-align: center;
  width: 100%;
  line-height: 20px;
}

.modal-dialog .modal-content .modal-header .modal-title p {
  margin-bottom: 0px;
  font-size: 16px;
}

.modal-dialog .modal-content .modal_btn {
  background: var(--modalbtn);
  padding: 8px;
  font-size: 10px;
  width: 100px;
  border: 1px solid transparent;
  border-radius: 10px;

}

.revealmodal .modal-dialog .modal-content .btn_sec {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
}

.modal-dialog .modal-content .modal_btn:hover {
  border: 1px solid var(--yellow);
  background: var(--yellow);
}

.form-control:focus {
  box-shadow: none !important;
}

.mintingmodal .modal-dialog .modal-content .modal-body .mining_nft_img {
  max-width: 190px;
  min-width: 190px;
  max-height: 250px;
  min-height: 250px;
  object-fit: cover;

}

.mintingmodal .modal-dialog .modal-content .modal-header {
  border-bottom: none;
  margin-top: 30px;
}

.mintingmodal .modal-dialog .modal-content .modal-body {
  /* max-width: 80%;
  margin: auto; */
}

.mintingmodal .modal-dialog .modal-content .modal-body .detail_sec .lists {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
}

.mintingmodal .modal-dialog .modal-content .modal-body .detail_sec .lists p {
  color: #fff;
}

.mintingmodal .modal-dialog .modal-content .modal-body .detail_sec {
  margin: auto;
  width: 80%;
}

.mintingmodal .modal-dialog .modal-content .modal-footer .bg_pattern_bottom {
  background: url('../images/modal_pattern.png');
  height: 100%;
  width: 100%;
  background-size: 100% 100px;
  position: absolute;
  background-repeat: no-repeat;
  bottom: 0px;
  transform: rotate(180deg);
  left: 0px;
}

.mintingmodal .modal-dialog .modal-content .modal-footer {
  border-top: none;
  position: relative;
}

.mintingmodal .modal-dialog .modal-content .modal-header .modal-title {
  font-size: 35px;
}

.banner_btn {
  background: url('../images/banner_btn.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 10px;
  font-family: DutchMediaeval_Regular;
  font-size: 24px;
  color: #fff;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  width: 200px;
  transition: all 0.9s;
  transition-timing-function: cubic-bezier(0.5, 3, 0, 1);
}

.banner_btn:hover {
  transform: scale(1.05, 1.05);
  color: #fff;
}

.mintingmodal .modal-dialog .modal-content .modal-footer .btn_sec {
  z-index: 4;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

/* .slide {
  position: absolute;
  left: -100px;
  width: 50%;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 0.5s forwards;
  animation-delay: 2s;
  height: 100vh;
  z-index: -2;
}

@-webkit-keyframes slide {
  100% { left: 0; }
}

@keyframes slide {
  100% { left: 0; }
}
.slideright{
  position: absolute;
  right: -100px;
  width: 50%;
  -webkit-animation: slide1 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide1 0.5s forwards;
  animation-delay: 2s;
  height: 100vh;
  z-index: -2;
}
@-webkit-keyframes slide1 {
  100% { right: 0; }
}

@keyframes slide1 {
  100% { right: 0; }
} */


.mintingmodal .modal-dialog .modal-content .modal-footer .btn_sec .banner_btn {
  padding-top: 15px;
  padding-bottom: 15px;
  color: #fff;
  font-size: 18px;
}

.modal-dialog .close_btn {
  width: 0;
  height: 0;
  border-top: 50px solid #98989838;
  border-left: 50px solid transparent;
  position: absolute;
  right: 0px;
  z-index: 5;
}

.modal-dialog .close_btn .close_text {
  color: #fff;
  top: -45px;
  bottom: 10px;
  right: 10px;
  position: absolute;
  cursor: pointer;
}

.mintingmodal .modal-dialog .detail_sec .counter_sec {
  display: flex;
  gap: 20px;
}

.mintingmodal .modal-dialog .detail_sec .counter_sec .count_btn {
  cursor: pointer;
  width: 5px;
}

.mintingmodal .modal-dialog .modal-content {
  border: none;
}

.cms_pages .cms_content_sec .heading {

  color: var(--yellow);
  margin-bottom: 5px;
}

.cms_pages .cms_content_sec .desc {
  color: #fff;
}

.cms_pages .cms_content_sec {
  padding-top: 50px;
}

.cms_pages .cms_content_sec ul {
  list-style: none;
}

.char_card_style .shadow_blur {
  position: absolute;
  width: 100%;
  background-color: #17171773;
  height: 50px;
  bottom: 27px;
  left: 0px;
  filter: blur(4px);
  z-index: 1;
}

.loading_text {
  color: #fff;
}

.nodata p {
  color: #fff;
}

.nodata {
  text-align: center;
}

.quest_pages.quest_detail {
  background: url('../images/qdetail_bg3.png');
  /* background: url('../images/quest_bg.png'); */
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: repeat;
  position: relative;
}

.quest_pages.quest_detail .quest_detail_btm {
  background: url('../images/detail_btm_bg.png');
  height: 350px;
  width: 100%;
  background-size: contain;
  background-repeat: repeat;
  position: absolute;
  bottom: 0px;
  background-size: 100% 100%;

}

@media only screen and (max-width: 991px) {
  .header .newmenu {
    background: var(--purple);
    transition: all 0.1s ease;
    padding: 20px;

  }

  .header .navbar-expand-lg .navbar-collapse {
    background: var(--purple);
    position: absolute;
    top: 50px;
    text-align: center;
    /* padding: 20px; */
    right: 0px;
    /* min-height: 0vh; */
    overflow: auto;
    transition: all 0.7s ease;

    width: 100%;
    height: 0px !important;
    min-height: 0vh;
    /* transform: translateY(-2em);
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s; */
  }

  .header.anims .newmenu {
    min-height: 100vh;
  }

  .header.anims .navbar-expand-lg .navbar-collapse.show {
    min-height: 100vh;
    width: 100%;
    /* padding: 20px; */


    /* transform: translateY(-2em);
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s; */
  }

  .header.anims .navbar-expand-lg .navbar-nav {
    -webkit-animation: fade 1s linear 0s;
    animation: fade 1s linear 0s;
    padding: 15px;
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .header.anims .navbarcol {
    display: block !important;
  }

  .header .navbarcol {
    display: none !important;
  }

  /* .header .navbar-expand-lg .navbar-collapse.show {
    visibility: visible; 
    opacity: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s; 
  } */

  .header .social_icons {
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;

  }

  .header .navbar-toggler .navbar-toggler-icon {
    background-image: url('../images/menu_burger.png') !important;
    height: 100%;
    width: 100%;
    background-size: 80% 75%;
    position: absolute;
    top: 10px;
    right: 10px;
    height: 25px;
    width: 30px;
  }

  .header.anims .navbar-toggler .navbar-toggler-icon {
    background-image: url('../images/new_img/close.png') !important;
    background-size: 60% 60%;
  }

  .header .navbar-toggler,
  .header .navbar-toggler:focus {
    box-shadow: none;
  }
}


@media only screen and (max-width: 575px) {
  .homepage .charactor_sec {
    margin-top: -245px;
    background-size: 160% 100%;
    background-image: url('../images/new_img/mcharacter_bg.png');
    padding-bottom: 50px;
  }
  .store_pages, .quest_pages{
    background-size: 100% 540px;
    background-image: url('../images/new_img/m_bgall3.png');
  }
 
  .inner_pages .top_sec .coin_sec .balance_coin {
    text-align: center;
  }

  .homepage .banner_sec .banner_crown {
    width: 120px;
    /* padding-top: 70px; */
    padding-top: 110px;
  }

  .homepage .banner_sec .rightflag_sec1,
  .homepage .banner_sec .rightflag_sec,
  .homepage .banner_sec .leftflag_sec1,
  .homepage .banner_sec .leftflag_sec {
    width: 140px;

  }

  .homepage .banner_sec .first_row .banner_rippon_sec p {
    width: 275px;
    font-size: 18px;
    line-height: 25px;
  }

  .card.quest_detailcard_style .first_row .leftside .quest_pic {
    width: 100%;
    min-width: unset;
    max-width: unset;
    min-height: 250px;
    max-height: 250px;
  }

  .card.quest_detailcard_style .first_row .leftside .img_sec {
    width: 100%;
    min-width: unset;
    max-width: unset;
    min-height: 250px;
    max-height: 250px;
  }

  .homepage .banner_btn {
    width: 220px;
    font-size: 18px;
  }

  .homepage .third_desc_sec .content_sec {
    width: 100%;
    padding: 20px;
    font-size: 16px;
    line-height: 25px;
    margin-top: 40px;
  }

  .homepage .patternsec .card_sec {
    padding: 10px;
    padding-top: 50%;
    padding-bottom: 80%;
  }

  .homepage .patternsec .design_card p {
    line-height: 25px;
    font-size: 18px;
  }

  .homepage .design_card {
    width: 100%;
    padding: 20px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background: url('../images/sm_design_card.png');
    background-size: 100% 100%;
  }

  .homepage .faq_sec .left_sec .sec_title {
    font-size: 28px;
    line-height: 40px;
  }

  .homepage .roadmap_sec .top_sec .first_row {
    margin-bottom: 20px;
  }

  .homepage .roadmap_sec .top_sec .second_row {
    height: unset;
    padding-top: 0px;
    margin-bottom: 20px;
  }

  .homepage .roadmap_sec .top_sec .third_row {
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .homepage .roadmap_sec .down_sec .first_row {
    padding-bottom: 0px;
  }

  .homepage .roadmap_sec .full_map {
    /* background: url('../images/roadmap_paper.png'); */
    background: unset;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 50px;
    background-position-x: -15px;
    margin-top: -80px;
  }

  .homepage .roadmap_sec .top_sec .second_row {
    padding-right: 0px;
  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    margin-bottom: 25px;
  }

  .homepage .faq_sec .left_sec .faq_img {
    position: relative;
    width: 250px;
    margin-bottom: 20px;
  }

  .homepage .tower_sec .bottom_sec .content_sec .leftside {
    position: unset;
    /* width: 75%; */
    width: 100%;
    padding: 10px;
  }

  .homepage .tower_sec .content_sec .leftside .sec_desc {
    font-size: 16px;
    line-height: 25px;
  }

  .homepage .charactor_sec .marquee_sec .chr_img {
    height: 270px;
    width: 200px;
  }

  .homepage .charactor_sec .content_sec {
    width: 100%;
    padding: 50px;
  }

  .homepage .charactor_sec .content_sec .sec_title {
    font-size: 40px;
    line-height: 40px;
  }

  .homepage .charactor_sec .content_sec .sec_desc {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
  }

  .footer .center_sec_list {
    display: unset;
    margin-bottom: 10px;
  }

  .footer .end_sec .social_icons {
    padding-top: 10px;
    justify-content: start;
  }
}

@media only screen and (min-width:576px) and (max-width:767px) {
  .homepage .third_desc_sec {
    padding-bottom: 120px !important;
  }

  .homepage .design_card {
    padding-top: 30px !important;
    padding-bottom: 50px;
  }

  .homepage .design_card .patternsec_p {
    margin-top: 80px;
  }

  .faq_img {
    width: 75% !important;
  }

  .homepage .card_sec_glow .img_glow {
    height: 200px;
    width: 200px;
    right: 3%;
    bottom: 0px;
  }

  .homepage .banner_sec .banner_crown {
    width: 180px;
    padding-top: 55px;
  }

  .homepage .banner_sec .rightflag_sec1,
  .homepage .banner_sec .rightflag_sec,
  .homepage .banner_sec .leftflag_sec1,
  .homepage .banner_sec .leftflag_sec {
    width: 220px;
    bottom: 100px !important;

  }

  .homepage .second_sec .gradsec_img {
    bottom: -30px !important;
  }

  .homepage .second_sec .king_img {
    height: 533px;
    object-fit: cover;
  }

  .homepage .banner_sec .first_row .banner_rippon_sec p {
    width: 250px;
    font-size: 20px;
  }

  .homepage .banner_btn {
    width: 250px;
    line-height: 30px;
    font-size: 20px;
  }

  .homepage .banner_sec {
    background-size: cover;
    background-position: center;
  }

  .homepage .banner_sec .left_img,
  .homepage .banner_sec .right_img {
    width: 45%;
    bottom: 30px;
  }

  .homepage .design_card {
    width: 100%;
    padding-left: 78px;
    padding-right: 78px;
    background: url('../images/tab_design_card.png');
    background-size: 100% 100%;
  }

  .homepage .patternsec .card_sec {
    padding: 0px;
    padding-top: 55px;
  }

  .homepage .charactor_sec {
    margin-top: 90px;
    background-size: 160% calc(100% + 220px);

  }

  .homepage .patternsec .story-pattern {
    height: 100%;
  }

  .homepage .third_desc_sec .content_sec {
    width: 100%;
    padding: 75px 30px;
  }

  .patternsec .patternsec_p h1 {
    font-size: 2rem !important;
    text-align: center;
  }

  .patternsec .patternsec_p p {
    font-size: 1.36rem;
    text-align: center;
  }

  .homepage .charactor_sec .marquee_sec .chr_img {
    height: 330px;
    width: 260px;
  }

  .homepage .charactor_sec .content_sec {
    width: 100%;
    padding: 50px;
    padding-top: 120px;

  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    text-align: center;
  }

  .homepage .roadmap_sec .full_map {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .homepage .roadmap_sec .top_sec .first_row {
    margin-bottom: 20px;
  }

  .homepage .roadmap_sec .top_sec .second_row {
    height: unset;
    padding-top: 0px;
    padding-right: 0px;
    margin-bottom: 20px;
  }

  .homepage .roadmap_sec .top_sec .third_row {
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .homepage .roadmap_sec .down_sec .first_row {
    padding-bottom: 0px;
  }

  .homepage .roadmap_sec .full_map {

    /* background: url('../images/roadmap_paper.png'); */
    background: unset;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 50px;
    background-position-x: -15px;
    margin-top: -80px;

  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    margin-bottom: 25px;
  }

  .homepage .tower_sec .bottom_sec .content_sec .leftside {
    position: unset;
    width: 60%;
    padding: 10px;
  }

  .homepage .tower_sec .content_sec .leftside .sec_desc {
    font-size: 16px;
    line-height: 27px;
  }

  .homepage .tower_sec .content_sec .leftside .sec_title {
    font-size: 28px;
    line-height: 40px;
  }

  .footer .left_sec p {
    text-align: center;
  }

  .footer .center_sec_list {
    justify-content: center;
  }

  .footer .end_sec .social_icons {
    padding-top: 10px;
  }

  .inner_pages .quest_detail .top_sec .coin_sec {
    position: unset;
  }

  .inner_pages .quest_detail .top_sec .coin_sec .balance_coin {
    text-align: center;

  }
  .store_pages, .quest_pages{
    background-size: 440px 540px;
    background-image: url('../images/new_img/m_bgall3.png');
  }
 
}

@media only screen and (max-width:767px) {
  .goldborder {
    display: none;
  }

  .store_pages .store_card_sec {
    padding: 30px 0px 20px 0px;
  }

  .card.quest_card_style .leftside .img_sec {
    text-align: center;
  }

  .card.quest_card_style .first_row .rightside .token_label {
    width: 100%;
  }

  .card.quest_card_style .first_row .rightside .title_card {
    text-align: center;
    margin-top: 20px;
  }

  .card.quest_card_style .first_row .rightside .time_duration {
    width: 100%;
    margin-top: 20px;
  }

  .quest_detail .detail_card_sec {
    width: 100%;
    margin: auto;
  }

  .quest_detail .inner_pages .coin_sec {
    position: unset;
  }
 


}

@media only screen and (min-width:768px) and (max-width:991px) {

  .homepage .banner_sec .rightflag_sec1,
  .homepage .banner_sec .rightflag_sec,
  .homepage .banner_sec .leftflag_sec1,
  .homepage .banner_sec .leftflag_sec {
    width: 360px;
  }

  .homepage .second_sec .gradsec_img {
    bottom: -30px;
  }

  .faq_img {
    width: 75% !important;
    height: 75% !important;
  }

  .homepage .second_sec .king_img {
    height: 533px;
    object-fit: cover;
  }

  .homepage .banner_sec .banner_crown {
    width: 185px;
    padding-top: 75px;
  }

  .homepage .banner_sec .first_row .banner_rippon_sec p {
    width: 400px;
    height: 130px;
    font-size: 20px;
    line-height: 30px;
  }

  .homepage .banner_btn {

    font-size: 20px;
    line-height: 32px;
    width: 250px;
  }

  .homepage .third_desc_sec .content_sec {
    width: 100%;

    padding: 85px 42px;
  }

  .homepage .patternsec .card_sec {
    padding: 0px;
    padding-top: 100px;
  }

  .homepage .design_card {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }

  .homepage .third_desc_sec .card_sec .design_card p {
    line-height: 30px;
    font-size: 20px;
  }

  .homepage .roadmap_sec .full_map {

    /* background: url('../images/roadmap_paper.png'); */
    background: unset;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 50px;
    background-position-x: -15px;
    margin-top: -80px;

  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    margin-bottom: 20px;
  }

  .homepage .tower_sec .bottom_sec .content_sec .leftside {
    top: 0px;
    width: 65%;
  }

  .homepage .tower_sec .content_sec .leftside .sec_desc {
    font-size: 18px;
    line-height: 25px;
  }

  .homepage .patternsec {
    padding-bottom: 10%;
  }

  .homepage .charactor_sec {
    margin-top: 30px !important;
  }

  .homepage .charactor_sec .content_sec {
    width: 100%;
    padding: 40px;
  }

  .homepage .charactor_sec .marquee_sec .chr_img {
    height: 300px;
    width: 230px;
  }

  .homepage .roadmap_sec .top_sec .first_row {
    margin-bottom: 20px;
  }

  .homepage .roadmap_sec .top_sec .second_row {
    height: unset;
    padding-top: 0px;
    padding-right: 0px;
    margin-bottom: 20px;
  }

  .homepage .roadmap_sec .top_sec .third_row {
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .homepage .roadmap_sec .down_sec .first_row {
    padding-bottom: 0px;
  }

  .homepage .card_sec_glow .glow_sec {
    text-align: center;
  }

  .homepage .card_sec_glow .img_glow {
    right: 6%;
  }

  .footer .left_sec p {
    text-align: center;
  }

  .footer .center_sec_list {
    justify-content: center;
  }

  .footer .end_sec .social_icons {
    padding-top: 10px;
  }

  .inner_pages .quest_detail .top_sec .coin_sec {
    position: unset;
  }

  .inner_pages .quest_detail .top_sec .coin_sec .balance_coin {
    text-align: center;

  }

  .homepage .banner_sec .right_img,
  .homepage .banner_sec .left_img {
    width: 50%;
  }
  .store_pages, .quest_pages{
    background-size: 490px 630px;
    background-image: url('../images/new_img/m_bgall3.png');
  }
}

@media only screen and (min-width:991px) and (max-width:1199px) {

  .homepage .banner_sec .rightflag_sec1,
  .homepage .banner_sec .rightflag_sec,
  .homepage .banner_sec .leftflag_sec1,
  .homepage .banner_sec .leftflag_sec {
    width: 370px;
  }

  .homepage .banner_sec .banner_crown {
    width: 185px;
    padding-top: 75px;
  }

  .homepage .banner_sec .first_row .banner_rippon_sec p {
    width: 400px;
    height: 130px;
    font-size: 20px;
    line-height: 30px;
  }

  .homepage .banner_btn {

    font-size: 20px;
    line-height: 32px;
    width: 250px;
  }

  .homepage .patternsec .card_sec {
    padding-left: 20px;
    padding-right: 20px;
  }

  .homepage .design_card {
    width: 100%;
  }

  .homepage .charactor_sec .marquee_sec .chr_img {
    height: 340px;
    width: 270px;
  }

  .homepage .roadmap_sec .top_sec .second_row {
    padding-top: 85px;
    height: 625px;
    padding-left: 30px;
  }

  .homepage .roadmap_sec .top_sec .third_row {
    padding-bottom: 10%;
  }

  .homepage .roadmap_sec .down_sec .first_row {
    padding-bottom: 50%;
    padding-top: 15%;
  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    line-height: 30px;
  }

  .homepage .faq_sec .left_sec .faq_img {
    width: 350px;
  }

  .homepage .tower_sec .bottom_sec .content_sec .leftside {
    top: 0px;
  }

  .homepage .tower_sec .content_sec .leftside .sec_desc {
    font-size: 18px;
    line-height: 30px;
  }

  .inner_pages .quest_detail .top_sec .coin_sec {
    position: unset;
  }

  .inner_pages .quest_detail .top_sec .coin_sec .balance_coin {
    text-align: center;

  }

  .card.quest_detailcard_style .first_row .leftside .img_sec,
  .card.quest_detailcard_style .first_row .leftside .quest_pic {
    min-width: 250px;
    max-width: 250px;
    min-height: 250px;
    max-height: 250px;
  }
  .store_pages, .quest_pages{
    background-size: 640px 730px;
    background-image: url('../images/new_img/m_bgall3.png');
  }
}

@media only screen and (min-width:1200px) and (max-width:1350px) {
  .homepage .banner_sec .banner_crown {
    width: 180px;
    padding-top: 65px;
  }

  .homepage .banner_sec .first_row .banner_rippon_sec p {
    width: 300px;
    height: 150px;
    font-size: 20px;
    line-height: 25px;
  }

  .homepage .banner_btn {
    font-size: 21px;
    line-height: 30px;
    width: 250px;
  }

  .homepage .banner_sec .rightflag_sec1,
  .homepage .banner_sec .rightflag_sec,
  .homepage .banner_sec .leftflag_sec1,
  .homepage .banner_sec .leftflag_sec {
    width: 460px;
  }

  .homepage .design_card {
    width: 85%;
    padding-left: 85px;
    padding-right: 85px;
  }

  .homepage .faq_sec .left_sec .faq_img {
    width: 400px;
  }

  .homepage .tower_sec .bottom_sec .content_sec .leftside {
    top: 0px;
    width: 65%;
  }

}

@media only screen and (max-width: 1350px) {
  .inner_pages .tab_sec {
    width: 100%;
  }

  .store_pages .store_card_sec,
  .store_pages .store_line_sec {
    width: 100%;
  }

  .store_pages .store_card_sec {
    width: 85%;
    /* margin-top: -40px; */
  }

}


@media only screen and (min-width:1400px) and (max-width:1799px) {
  .store_pages .store_card_sec {
    padding: 130px 60px 100px 60px !important;
  }
}

@media only screen and (min-width:1800px) {
  .store_pages .store_card_sec {
    padding: 130px 60px 100px 60px;
  }
}

@media only screen and (min-width:4300px) {
  .store_pages .store_card_sec {
    padding: 156px 70px 130px 70px;
  }
}

@media only screen and (min-width: 1350px) and (max-width: 1400px) {

  .homepage .banner_sec .banner_crown {
    width: 200px;
    padding-top: 60px;
  }

  .homepage .banner_sec .rightflag_sec1,
  .homepage .banner_sec .rightflag_sec,
  .homepage .banner_sec .leftflag_sec1,
  .homepage .banner_sec .leftflag_sec {
    width: 550px;
  }
}

@media only screen and (min-width: 1351px) {
  .homepage .banner_sec .banner_crown {
    width: 330px;
    /* padding-top: 70px; */
  }
}

@media only screen and (min-width:1200px) {
  .quest_pages.quest_detail .top_sec {
    position: relative;
  }

  .inner_pages .quest_pages.quest_detail .top_sec .desc_sec {
    position: unset;
  }

  .inner_pages .quest_pages.quest_detail .top_sec .coin_sec {
    top: 0px;
    right: -30px;
  }
}


@media only screen and (min-width:1700px) {
  .homepage .roadmap_sec .mapsec .sec_desc {
    font-size: 20px;
    line-height: 30px !important;
  }

  .card.quest_card_style .first_row .rightside .title_card {
    font-size: 23px;
  }

  .card.quest_card_style .first_row .rightside .catg {
    font-size: 20px;
  }

  .card.quest_card_style .first_row .rightside .token_label {
    font-size: 18px;
  }

  .card.quest_card_style .bottom_link a {

    font-size: 20px;
  }

  .card.quest_card_style .first_row .rightside .count {
    font-size: 18px;
  }

  .quest_detailcard_style .rightside .det_title {
    font-size: 20px;
  }

  .quest_detailcard_style .rightside .det_desc {
    font-size: 18px;
  }

  .quest_detail .recent_quest .page_title {
    font-size: 24px;
  }

  .inner_pages .top_sec .page_desc {
    font-size: 18px;
  }
}


.scrollanimchar {
  display: flex;
  flex-direction: row;
  gap: 10px;

}

@media only screen and (min-width:1200px) {

  .homepage .charactor_sec .marquee_sec {
    padding-bottom: 15%;
  }

  .homepage .training_sec {
    margin-top: -90px;
  }

  .homepage .charactor_sec .content_sec {
    padding-bottom: 20px;
  }
}

/* kr css */
.kr_fontsize {
  font-size: 45px !important;
}

.kr_fontsize::first-letter,
.kr_fontsizef {
  font-size: 80px !important;
}

/* .loader_whole{
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-image: url('../images/banner_cloud.webp');


}
.loader_cont_aling{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader_cont_aling{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loader_center_img{
  width: 200px;
  height: 250px;
  animation-name: logoshrink;
  animation-timing-function: ease;
  animation-duration: .5s;
  animation-delay: 1s;
  animation-iteration-count: 1;
}
@keyframes logoshrink{
  0%{
    width: 200px;
  }
  50%{
    width: 100px;
  }
  100%{
    width: 0px;
  }
}
.loader_loadtxt{
  color: #fff;
  font-size: 25px;
  font-family: 'Averia Serif Libre', cursive !important;
  text-align: center;
}
.screening_left{
  width: 50%;
  height: 100vh;
  background-color: #0d1421;
  background-image: url('../images/new_img/left.png');
  background-position: right;
  background-size: cover ;
background-repeat: no-repeat;
display: flex;
justify-content: flex-end;
  animation-name: cinema;
  animation-duration: 5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-delay: .5s;
  overflow: hidden;
}
.screening_left img{
  width: 80%;
  height: 100vh;
  object-fit: cover;
  object-position: right;
}

.screening_right{
  width: 50%;
  height: 100vh;
  background-color: #0d1421;
  background-image: url('../images/new_img/right.png');
  background-position: left;
  background-size: cover ;
background-repeat: no-repeat;
  animation-name: cinema;
  animation-duration: 5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-delay: .5s;
}
.screening_right img{
  width: 80%;
  height: 100vh;
  object-fit: cover;
  object-position: right;
}
@keyframes cinema{
  0%{
    width: 50%;
    
  }
  50%{
    width: 25%;
  }
  100%{
    width: 0%;
  opacity: 0;
  }
} */

/* .loader_whole{
  width: 100%;
  height: 100vh;
  background-image: url('../images/new_img/jpeg_logo.jpg');
  background-position: top;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  transition: all 0.8s ease;
} */
.loader_main {
  width: 100%;
  min-height: 100vh;
  background: #1f1f34;
  margin: auto;
  transition: all 0.9s ease;
  /* -webkit-transition: -webkit-transform 0.9s ease; */
  position: absolute;
  z-index: 9999;
  left: 50%;
  transform: translate(-50%, 0%);

  /* z-index: 9999;
  position: relative; */
}

/* .loader_main:hover {
  width: 10%;
  background: #1f1f34;
  margin: auto;
} */
.loader_whole {
  width: 100%;
  min-height: 100vh;
  background: #1f1f34;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('../images/new_img/jpeg_logo.jpg');
  background-size: 1200px auto;
  background-repeat: no-repeat;
  background-position: center;


  /* background-position: center; */
}

.animmain .loader_whole {
  background-size: 1200px auto;
}

.animmain {
  background: url("../images/banner_cloud.webp");
  background-size: 100% 100%;

}

.loader_main.anims {
  width: 0% !important;
  /* background: transparent !important; */
}


.loader_whole img {
  width: 100%;
  object-fit: cover;
  height: 100vh;

}

.loader_main.anims .loader_whole {
  transition: opacity 3s ease-in-out;


}

.loader_whole .loader_center_img {
  width: 200px !important;
  height: 200px !important;
  object-fit: contain !important;
  transition: all .9s ease
}

.loader_main.anims .loader_whole .loader_center_img {
  transform: scale(0.8)
}

.loader_loadtxt {
  color: #fff;
  font-size: 25px;
  font-family: 'Averia Serif Libre', cursive !important;
  text-align: center;
}

/* .loader_main.anims .loader_whole{
  animation:.5s ease .5s normal forwards 1 fadein;
  -webkit-animation:.5s ease .5s normal forwards 1 fadein;
}

@keyframes fadein{from{opacity:1}
to{opacity:0}
} */

.scroll_whole {
  width: 100%;
  height: 100vh;
  position: absolute;
}

.scrolling_rel {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.scroll_left,
.scroll_right {
  width: 50%;
  height: 100vh;
  position: absolute;
}

.scroll_left {
  left: -100%;
  animation-name: cinemaleft;
  animation-duration: 1s;
  animation-iteration-count: 1;
  /* animation-timing-function: ease; */
  animation-timing-function: linear;
  /* animation-play-state: ; */

}

.inner_pages .header .header_logo {
  display: none;

}

.inner_pages .header .allside {
  height: 70px !important;
  position: absolute;
  top: 0;
  -webkit-transform: scale(1.5) translate(12.5%, 20.5%);
  transform: scale(1.5) translate(12.5%, 20.5%);
  width: 52px;

}

.inner_pages .drop_pages .select_all_sec {
  margin-bottom: 40px;
  display: flex;
  justify-content: end;
}

.inner_pages .drop_pages .sale_card_coll {
  position: relative;
}

.inner_pages .drop_pages .sale_card_coll .select_card_sec {
  position: absolute;
  top: 0px;
  right: 10px;
}

.inner_pages .drop_pages .sale_card_coll .select_card_sec.form-check-input {
  height: 20px;
  width: 20px;
  box-shadow: none !important;
}

.inner_pages .drop_pages .sale_card_coll .select_card_sec .form-check-input:checked {
  background-color: #f2a001 !important;
  border: 1px solid #f2a001 !important;
  box-shadow: none !important;
}

.inner_pages .drop_pages .select_all_sec .form-check-input {
  height: 20px;
  width: 20px;
  box-shadow: none !important;
}

.inner_pages .drop_pages .select_all_sec .form-check-input:checked {
  background-color: #f2a001 !important;
  border: 1px solid #f2a001 !important;
}

.inner_pages .drop_pages .select_all_sec label {
  color: #f2c80f;
  margin-left: 10px;
  font-size: 18px;
}

@keyframes cinemaleft {
  0% {
    left: -100%;
  }

  25% {
    left: -75%;
  }

  50% {
    left: -50%;
  }

  75% {
    left: -25%;
  }

  100% {
    left: 2%;
  }
}

.scroll_right {
  right: -100%;
  animation-name: cinemaright;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-delay: 0s;
}

@keyframes cinemaright {
  0% {
    right: -100%;
  }

  25% {
    right: -75%;
  }

  50% {
    right: -50%;
  }

  75% {
    right: -25%;
  }

  100% {
    right: 2%;
  }
}

.scroll_left img,
.scroll_right img {
  width: 100%;
  height: 100vh;

}

/* end of kr css */

/* kr media query */
@media (width > 1920px) {

  .container.custody_container,
  .container-fluid.custody_container {
    max-width: 1600px !important;
  }

  .tower_full {
    min-width: 100%;
  }

  .homepage .card_sec_glow .img_glow {
    right: 3%;
    width: 900px;
    height: 900px;
    bottom: -350px;
  }

  /* .relativing_abs{
    position: relative;
    margin-top: 630px;
  } */
  .homepage .tower_sec .bottom_sec .content_sec .leftside {
    padding-left: 0 !important;
    padding-right: 0 !important;
    /* position: absolute;
    top: -600px;
    width: 50%;
    padding: 80px; */
  }

}

/* @media (1350px < width < 1920px){
  .homepage .card_sec_glow .img_glow{
    right: 2%;
  }
} */

@media (1999px < width < 3000px) {

  .averia_font,
  .averia_font p {
    font-size: 20px;
  }

}

@media only screen and (min-width: 1701px) {

  /* .faq_img{
    width: 900px !important;
  } */
  .averia_font,
  .averia_font p {
    font-size: 20px !important;
  }

  .patternsec .patternsec_p h1 {
    font-size: 35px;
    margin-bottom: 30px;
  }

  .homepage .design_card {
    width: 1060px !important;
    padding-left: 6%;
    padding-right: 7%;
  }

  .patternsec .patternsec_p p {
    font-size: 1.36rem !important;
  }

  .homepage .third_desc_sec .content_sec p {
    width: 830px !important;
  }
}

@media (991px < width < 1200px) {
  .homepage .tower_sec .bottom_sec .content_sec .leftside {
    padding: 10px;
  }

  .homepage .card_sec_glow .img_glow {
    right: 13%;
  }
}

@media only screen and (max-width: 3000px) and (min-width: 1401px) {
  .homepage .banner_sec .leftflag_sec {
    width: 39%;
  }
}


@media (768px < width < 992px) {
  .homepage .tower_sec .bottom_sec .content_sec .leftside {
    padding: 10px;
  }

}

@media (576px < width < 768px) {
  /* .homepage .card_sec_glow .img_glow{
    right: -5%;
  } */
}

@media (width < 576px) {
  .inner_container {
    max-width: 90% !important;
  }

  .homepage .patternsec .story-pattern::before {
    background-size: contain;
  }

  .homepage .roadmap_sec .top_sec .title,
  .homepage .team_sec .title_top .sec_title {
    font-size: 40px;
  }

  .savagesection .sec_title {
    font-size: 37px;
  }

  .savagesection {
    padding: 120px 0px 30px 0px;
    width: 100%;
  }

  .footer .left_sec p,
  .footer .center_sec {
    text-align: center;
  }

  .footer .end_sec .social_icons {
    justify-content: center;

  }

  .footer .end_sec .social_icons ul {
    padding: 0;
  }

  .homepage .charactor_sec {
    z-index: 1;
  }

  .marquee_sec {
    display: none;
  }
}

@media (width < 992px) {
  .homepage .training_sec .rightsec .sec_title {
    text-align: center;
  }

  .homepage .training_sec .first_row {
    flex-direction: column-reverse;
  }

  .homepage .training_sec .first_row {
    margin-top: 50px !important;
  }
}

@media (449px < width < 576px) {
  .homepage .training_sec .rightsec .sec_title {
    font-size: 40px;
  }

  .homepage .training_sec .rightsec .sec_desc {
    font-size: 18px;
    line-height: 30px;
  }
}

@media (319px < width < 450px) {
  .homepage .roadmap_sec .mapsec .sec_desc {
    font-size: 15px;
    padding: 0px 10px;
    text-align: center;
  }

  .homepage .roadmap_sec .full_map {
    padding: 15px;
  }

  .homepage .training_sec .rightsec .sec_desc,
  .homepage .charactor_sec .content_sec .sec_desc,
  .homepage .patternsec .design_card p {
    font-size: 16px;
    line-height: 25px;
  }

  .savagesection .sec_desc {
    font-size: 16px;
    line-height: 25px;
  }

  .homepage .training_sec .rightsec .sec_title {
    font-size: 40px;
  }

  .homepage .charactor_sec .content_sec {
    padding: 10px;
  }

  .homepage .tower_sec .content_sec .leftside .sec_title {
    font-size: 16px;
  }

}

@media (width > 1100px) {
  .homepage .banner_sec .banner_crown {
    width: 35%;
    position: relative;
    top: -30px;
    /* top: -140px; */
  }
}

@media (999px < width < 1100px) {
  .homepage .banner_sec .banner_crown {
    width: 27%;
  }
}

@media (899px < width < 1100px) {
  .homepage .banner_sec .banner_crown {
    width: 40%;
    position: relative;
    top: -100px;
  }
}

@media (799px < width < 900px) {
  .homepage .banner_sec .banner_crown {
    width: 35%;
  }
}

@media (799px < width < 900px) {
  .homepage .banner_sec .leftflag_sec {
    bottom: 50px;
    left: -30px;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 60px;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 0px;
  }
}

@media (699px < width < 800px) {
  .homepage .banner_sec .banner_crown {
    width: 40%;
  }
}

@media (599px < width < 700px) {
  .homepage .banner_sec .banner_crown {
    width: 45%;
  }
}

@media (width > 1300px) {
  .homepage .banner_sec .banner_crown {
    width: 35%;
  }

}

@media (899px < width < 1100px) {
  .homepage .banner_sec .banner_crown {
    width: 40%;
    position: relative;
    top: -75px;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 75px;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 30px;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 75px;
  }

}

/* @media (899px < width < 992px){
  .savagesection{
    margin-top: 350px;
  }
} */

@media (799px < width < 900px) {
  .homepage .banner_sec .banner_crown {
    width: 45%;
    position: relative;
    top: -75px;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 75px;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 30px;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 75px;
  }

  /* .savagesection{
    margin-top: 320px;
  } */

}

@media (767px < width < 800px) {
  .homepage .banner_sec .banner_crown {
    width: 60%;
    position: relative;
    top: -75px;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 25px;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 00px;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 25px;
  }

  /* .savagesection{
    margin-top: 250px;
  } */

}

@media (699px < width < 768px) {
  .homepage .banner_sec .banner_crown {
    width: 60%;
    position: relative;
    top: -75px;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 80px;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 50px;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 80px;
  }

  /* .savagesection{
    margin-top: 250px;
  } */


}

@media (599px < width < 700px) {
  .homepage .banner_sec .banner_crown {
    width: 60%;
    position: relative;
    top: -75px;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 50px;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 0px;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 30px;
  }

  /* .savagesection{
    margin-top: 200px;
  } */

}

@media (575px < width < 600px) {

  /* .homepage .banner_sec .banner_crown{
    width: 75%;
    position: relative;
    top: -120px;
  } */
  .homepage .banner_sec .banner_crown {
    width: 65%;
    position: relative;
    top: -75px;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 50px;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 0px;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 30px;
  }

  /* .savagesection{
    margin-top: 150px;
  } */
}

@media (499px < width < 576px) {
  .homepage .banner_sec .banner_crown {
    width: 70% !important;
    position: relative;
    top: -80px;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 90px;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 70px;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 90px;
  }

  .kr_fontsize {
    font-size: 45px !important;
  }

  .kr_fontsize::first-letter,
  .kr_fontsizef {
    font-size: 65px !important;
  }
}

@media (399px < width < 450px) {
  .homepage .banner_sec .banner_crown {
    width: 75%;
    position: relative;
    top: -90px;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 80px;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 50px;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 80px;
  }
}

@media (499px < width < 550px) {
  .homepage .faq_sec .left_sec .img_glow {
    right: 27%;
  }

  /* .savagesection{
    margin-top: 70px;
  } */
}

@media (449px < width < 500px) {
  .homepage .faq_sec .left_sec .img_glow {
    right: 19%;
  }

  /* .savagesection{
    margin-top: 50px;
  } */
}

@media (399px < width < 450px) {
  .homepage .faq_sec .left_sec .img_glow {
    right: -8%;
  }

  .footer .left_sec p,
  .footer .center_sec .center_sec_list a {
    font-size: 13px;
  }
}

@media (449px < width < 500px) {
  .homepage .banner_sec .leftflag_sec {
    bottom: 80px;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 60px;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 80px;
  }

  .homepage .banner_sec .banner_crown {
    width: 70%;
    position: relative;
    top: -100px;
  }

  .kr_fontsize {
    font-size: 40px !important;
  }

  .kr_fontsize::first-letter,
  .kr_fontsizef {
    font-size: 60px !important;
  }

}

@media (399px < width < 450px) {
  .homepage .banner_sec .leftflag_sec {
    bottom: 70px;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 50px;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 70px;
  }

  .kr_fontsize {
    font-size: 35px !important;
  }

  .kr_fontsize::first-letter,
  .kr_fontsizef {
    font-size: 55px !important;
  }

}

@media (319px < width < 400px) {
  .homepage .faq_sec .left_sec .img_glow {
    right: -8%;
  }

  .homepage .banner_sec .banner_crown {
    width: 80%;
    position: relative;
    top: 0px;
  }

  .footer .left_sec p,
  .footer .center_sec .center_sec_list a {
    font-size: 12px;
  }

  .footer {
    padding: 20px 10px;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 60px;
    width: 200px;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 45px;
    width: 45%
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 60px;
    width: 133px;
  }

  .homepage .banner_sec .leftflag_sec1 {
    bottom: 40px;
    width: 45%;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 30px;
    width: 120px;
  }

  .kr_fontsize {
    font-size: 30px !important;
  }

  .kr_fontsize::first-letter,
  .kr_fontsizef {
    font-size: 50px !important;
  }
}

@media (width > 575px) {
  .swiper_contents {
    display: none;
  }
}


.headermint {
  cursor: pointer;
  font-size: 14px;
}

.tower_full {
  position: sticky;
  bottom: 0px;
  z-index: -1;
}

.homepage .tower_sec .bottom_sec .content_sec .leftside {
  /* min-height: 2330px !important; */
}

.faq_img {
  max-width: 100%;
  z-index: 10;
  position: relative;
  top: 6px;
  width: 700px;
  height: 500px;
}

.patternsec .patternsec_p p {
  font-size: 18px;
  line-height: 30px;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 25px !important;


}

.patternsec .patternsec_p h1 {
  font-size: 40px;
  margin-bottom: 30px;
}

.img_glow {
  position: relative;
  z-index: 10;
}

.king_img {
  min-height: 300px;
  object-fit: cover;
  object-position: center;
}

.char_img1 {
  max-width: 100%;
  min-width: 100%;
}

.wholes_header {
  display: block;
}

/* .header_logo{
  display: none;
} */
.logo_hide.wholes_header {
  display: block;
}

/* .logo_hide.header_logo{
  display: block;
} */
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #fff;

}

.swiper_img {
  min-width: 100%;
  max-width: 100%;
}

.reward {
  color: #F5F5F5;
}

.averia_font {
  font-family: 'Averia Serif Libre', cursive !important;
}

.imfell_font {
  font-family: 'IM Fell English', serif !important;
}

.averia_font_small p,
.averia_font_small {
  font-weight: 300 !important;

}

.swiper_contents .swiper-slide.swiper-slide-active {
  transform: scale(1.2);
}

.sticking.content_sec {
  position: sticky;
  top: -50px;
}

.homepage .banner_sec .first_row.scroll .banner_crown {
  top: 30px;

}

.krbtn_sec.btn_sec {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* common scrollbar  */
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--yellow);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

.react_sel .react-select__control {
  background: transparent;
  border-radius: 10px;

}

.mining_pages .mint_form .prev_sec {
  display: flex;
  justify-content: end;
}

.mining_pages .mint_form .prev_sec button {
  height: 40px;
  padding: 0px;
  width: 100px;
}

.react_sel .react-select__single-value {
  color: #fff;
}

/* end of common scrollbar */
@media only screen and (max-width:400px) {
  .swiper_contents .swiper-horizontal {
    padding: 0px !important;
    padding-top: 50px !important;
  }

}


@media only screen and (min-width:401px) and (max-width:480px) {
  .swiper_contents .swiper-horizontal {
    padding: 0px !important;
    padding-top: 50px !important;
  }

  .swiper_contents .swiper-horizontal .swiper-wrapper {
    margin-bottom: 13px;
  }
}


@media only screen and (min-width:481px) and (max-width:500px) {
  .swiper_contents .swiper-horizontal {
    padding: 0px !important;
    padding-top: 50px !important;
  }
}

@media only screen and (min-width:501px) and (max-width:575px) {
  .swiper_contents .swiper-horizontal {
    padding: 90px !important;
    margin-bottom: 30px;
  }
}

.footer_row_new div {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width:991px) {
  .allside {
    width: 70px;
    height: 50px;
    margin-top: -12px;
  }

  .savagesection {
    padding: 120px 0px 30px 0px;
    width: 100%;
  }

  .footer_row_new div {
    justify-content: center;
  }

  .footer_social_list_pl_alg {
    padding-left: 0px !important;
  }

  .quest_pages.quest_detail .quest_detail_btm {

    height: 100px;
  }
  .inner_pages .top_sec .coin_sec {
    position: unset;
  }.inner_pages .top_sec .coin_sec .balance_coin{
    text-align: center;
  }
}




@media only screen and (max-width:575px) {
  .savagesection .sec_desc {
    font-size: 18px;
  }

  .savagesection .sec_title {
    margin-bottom: 20px;
  }

  .design_card {
    margin-top: -40px !important;
  }

  .swiper_contents .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 15px !important;
  }

  .swiper_contents .swiper-horizontal {
    padding-bottom: 70px !important;
  }
}

.swiper_contents .swiper-pagination-bullet {
  background: #e1d7e3 !important;
  width: 10px !important;
  height: 10px !important;
}

.design_card {
  margin-top: -85px !important;
}

@media only screen and (min-width:1351px) {
  .homepage .tower_sec .bottom_sec .content_sec .leftside {
    width: 65% !important;
  }
}

@media only screen and (max-width: 575px) and (min-height: 800px) {
  .homepage .banner_sec .banner_crown {
    padding-top: 65px;
  }

  .homepage .banner_sec {
    min-height: 100vh;
  }
}

@media only screen and (max-width:575px) {
  .banner_btn {
    font-size: 18px;
    width: 150px;
  }

  .homepage .banner_sec .right_img {
    right: -10px;
  }

  .homepage .banner_sec .left_img {
    left: -10px;
  }

  .design_card {
    margin-top: -40px !important;
  }

  .homepage .card_sec_glow .img_glow {
    height: 150px;
    width: 150px;
    right: 0%;
    bottom: 0px;
  }
}

.leftside_fasqs {
  padding-top: 130px !important;
}

.margin_top_minu_daf {
  margin-top: -100px;
}

.roadmap_sec {
  max-width: 1410px;
  margin: auto;

}

@media (1799px < width < 1921px) {
  .homepage .card_sec_glow .img_glow {
    height: 500px;
    width: 500px;
    right: 17%;
    bottom: -25%;
  }
}

@media (1699px < width < 1800px) {
  .homepage .card_sec_glow .img_glow {
    height: 300px;
    width: 300px;
    right: 27%;
    bottom: -60px;

  }
}

@media (1599px < width < 1700px) {
  .homepage .card_sec_glow .img_glow {
    height: 400px;
    width: 400px;
    right: 22%;
    bottom: -20%;
  }
}

@media (1499px < width < 1600px) {
  .homepage .card_sec_glow .img_glow {
    height: 300px;
    width: 300px;
    right: 23%;
    bottom: -55px;
  }
}

@media (1399px < width < 1500px) {
  .homepage .card_sec_glow .img_glow {
    height: 300px;
    width: 300px;
    right: 20%;
    bottom: -30px;
  }
}

@media (500px < width < 576px) {
  .homepage .card_sec_glow .img_glow {
    height: 150px;
    width: 150px;
    right: 5%;
    bottom: 0px;
  }
}

@media only screen and (max-width:575px) {
  .leftside_fasqs {
    padding-top: 60px !important;
  }

  .margin_top_minu_daf {
    margin-top: -50px !important;
  }

  .inner_pages .top_sec .page_desc {
    width: 100%;
  }
}

@media only screen and (min-width:576px) and (max-width:767px) {

  .leftside_fasqs {
    padding-top: 100px !important;
  }

  .margin_top_minu_daf {
    margin-top: -50px !important;
  }
}

@media only screen and (min-width:768px) and (max-width:991px) {
  .leftside_fasqs {
    padding-top: 100px !important;
  }

  .margin_top_minu_daf {
    margin-top: -50px !important;
  }
}

@media only screen and (min-width:992px) and (max-width:1199px) {
  .leftside_fasqs {
    padding-top: 120px !important;
  }

  .margin_top_minu_daf {
    margin-top: -50px !important;
  }

  .homepage .third_desc_sec .content_sec {
    width: 95%;
  }

  .homepage .charactor_sec .content_sec {
    width: 100%;
  }

  .quest_pages.quest_detail .quest_detail_btm {

    height: 160px;
  }
}

@media (1500px < width < 1921px) {}

@media only screen and (min-width:2001px) and (max-width:2500px) {
  .leftside_fasqs {
    padding-top: 350px !important;
  }

  .margin_top_minu_daf {
    margin-top: -100px !important;
  }
}


@media only screen and (min-width:2501px) and (max-width:3000px) {
  .leftside_fasqs {
    padding-top: 400px !important;
  }

  .margin_top_minu_daf {
    margin-top: -100px !important;
  }
}

@media only screen and (min-width:3001px) and (max-width:3500px) {
  .leftside_fasqs {
    padding-top: 450px !important;
  }

  .margin_top_minu_daf {
    margin-top: -100px !important;
  }
}

@media only screen and (min-width:3501px) and (max-width:4000px) {
  .leftside_fasqs {
    padding-top: 500px !important;
  }

  .margin_top_minu_daf {
    margin-top: -100px !important;
  }
}

@media only screen and (min-width:4001px) and (max-width:4500px) {
  .leftside_fasqs {
    padding-top: 550px !important;
  }

  .margin_top_minu_daf {
    margin-top: -100px !important;
  }
}

@media only screen and (min-width:4501px) and (max-width:5000px) {
  .leftside_fasqs {
    padding-top: 600px !important;
  }

  .margin_top_minu_daf {
    margin-top: -100px !important;
  }
}

@media only screen and (min-width:5001px) {
  .leftside_fasqs {
    padding-top: 700px !important;
  }

  .margin_top_minu_daf {
    margin-top: -100px !important;
  }
}

/* end of kr media query */

@media only screen and (min-width:3000px) {
  .homepage .charactor_sec {
    background-size: 1200px 100%;
  }
}

@media only screen and (min-width:2500px) {
  .homepage .patternsec {
    background-size: contain;
    background-repeat: repeat;
  }

}

@media (1350px < width < 1500px) {
  .homepage .banner_sec .rightflag_sec1 {
    bottom: 0px;
  }
}

@media (width > 2200px) {
  .homepage .banner_sec .rightflag_sec1 {
    bottom: 300px;
  }
}

@media (width < 577px) {
  .homepage .third_desc_sec {
    padding-bottom: 220px;
  }

  .header .header_logo {
    max-height: 70px;
    width: unset !important;
    top: -8px;
  }
}

@media screen and (max-width:991px) {
  .loader_whole {
    background-size: 350px auto !important;
  }

  .loader_whole .loader_center_img {
    width: 160px !important;
    height: 160px !important;
    object-fit: contain !important;
  }
}

@media screen and (min-width:1920px) {
  .loader_whole .loader_center_img {
    width: 250px !important;
    height: 790px !important;
    object-fit: contain !important;
  }

  .loader_loadtxt {
    font-size: 50px;
  }

}


@media screen and (max-width:767px) {
  .homepage .patternsec .story-pattern {
    clip-path: polygon(0% 0%, 6.25% 3%, 12.5% 0%, 18.75% 3%, 25% 0%, 31.25% 3%, 37.5% 0%, 43.75% 3%, 50% 0%, 56.25% 3%, 62.5% 0%, 68.75% 3%, 75% 0%, 81.25% 3%, 87.5% 0%, 93.75% 3%, 100% 0%, 100% 100%, 93.75% 97%, 87.5% 100%, 81.25% 97%, 75% 100%, 68.75% 97%, 62.5% 100%, 56.25% 97%, 50% 100%, 43.75% 97%, 37.5% 100%, 31.25% 97%, 25% 100%, 18.75% 97%, 12.5% 100%, 6.25% 97%, 0 100%) !important;
  }

  .faq_img {

    width: 100%;
    height: 100%;
  }

  .homepage .second_sec .gradsec_img {
    bottom: -5px;
  }

}

@media screen and (max-width:1199px) and (min-width:992px) {
  .homepage .charactor_sec {
    margin-top: -100px;
  }
}

@media screen and (max-width:575px) {
  .homepage .third_desc_sec .content_sec {
    height: 320px;
  }

  .wholes_header {
    display: block;
  }

  .homepage .patternsec .story-pattern {
    height: 75%;
  }

  .homepage .patternsec {
    z-index: 2;
  }

  .homepage p {
    font-size: 16px;
  }

  .patternsec .patternsec_p h1 {
    font-size: 24px;
  }

  .loader_main {
    transition-property: width;
    transition-duration: 0.09s;
    transition-timing-function: ease;
    width: 100%;

  }

  .loader_whole {
    background-size: contain !important;
  }

}

@media screen and (max-width:991px) {
  .homepage .training_sec .rightsec .sec_desc {
    text-align: center;
  }
}

@media screen and (min-width:1201px) {

  .homepage .training_sec .rightsec .sec_desc {
    width: 575px;
  }
}

@media screen and (min-width:1401px) and (max-width:1700px) {
  .homepage .second_sec .gradsec_img {
    position: absolute;
    bottom: -80px;
  }
}

@media screen and (min-width: 1701px) {
  .homepage .second_sec .gradsec_img {
    position: absolute;
    bottom: -10%;
    height: 15%;
  }
}

@media screen and (min-width: 1501px) {
  .homepage .charactor_sec .content_sec {
    width: 1150px;
    margin: auto;
  }

}

@media screen and (min-width: 1401px) {

  .homepage .third_desc_sec .content_sec p {
    width: 900px;
    margin: auto;
  }

  .homepage .third_desc_sec .content_sec {
    padding: 110px 0px;
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 550px) {

  .training_sec .rightsec .averia_font p {
    font-size: 1.36rem;
  }

  .third_desc_sec .content_sec .averia_font p {
    font-size: 1.36rem;
  }

  .patternsec .patternsec_p p {
    font-size: 1.36rem;
  }

}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .homepage .third_desc_sec .content_sec p {
    width: 100%;
    margin: auto;
  }

  .homepage .charactor_sec .content_sec .sec_desc {
    width: 80%;
    margin: auto;
  }

  .patternsec .patternsec_p p {
    text-align: center;
  }
}


@media screen and (min-width: 1450px) {
  .inner_container {
    max-width: 1450px !important;
  }
}

/* new css */




@media screen and (min-width: 320px)and (max-width: 350px) {
  .homepage .third_desc_sec .content_sec {
    padding: 15px;
  }

  .homepage .patternsec .card_sec {
    padding-top: 32%;
  }

  .homepage .card_sec_glow .img_glow {
    right: -3%;
    bottom: -15%;
  }

  .inner_container {
    max-width: 95% !important;
  }

  .charactor_sec .content_sec .sec_desc p {
    font-size: 15px;
  }

  .homepage p {
    font-size: 15px;
  }

  .homepage .roadmap_sec .full_map {
    padding-left: 0px;
    padding-right: 0px;
  }

  .homepage .patternsec .design_card {
    background-size: 100% 100%;
    margin-bottom: 35px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
  }

  .homepage .patternsec .design_card p {
    font-size: 16px;
  }

  .homepage .patternsec .design_card .patternsec_p {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .homepage .banner_sec .banner_crown {
    padding-top: 150px;
  }

  .homepage .second_sec .king_img {
    /* transform: scale(2.0); */
    height: 533px;
    object-fit: cover;
    /* width: 100%; */
  }

  .homepage .second_sec .gradsec_img {
    /* display: none; */
  }

  .homepage .third_desc_sec .content_sec {
    margin-top: 30px;
  }

  .homepage .third_desc_sec .content_sec {
    height: 240px;
  }

  .homepage .third_desc_sec {
    padding-bottom: 260px;
  }

  .trained {
    display: block;
    bottom: 30px;
    width: 100%;
  }
}

@media screen and (min-width: 351px)and (max-width: 400px) {
  .homepage .third_desc_sec .content_sec {
    padding: 18px;
  }

  .homepage .patternsec .card_sec {
    padding-top: 32%;
  }

  .homepage .card_sec_glow .img_glow {
    right: -3%;
    bottom: -15%;
  }

  .inner_container {
    max-width: 95% !important;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 45px;
  }

  .homepage .banner_sec .leftflag_sec1 {
    bottom: 70px;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 65px;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 50px;
  }

  .charactor_sec .content_sec .sec_desc p {
    font-size: 15px;
  }

  .homepage p {
    font-size: 15px;
  }

  .homepage .roadmap_sec .full_map {
    padding-left: 10px;
    padding-right: 10px;
  }

  .homepage .patternsec .design_card {
    background-size: 100% 100%;
    margin-bottom: 35px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 60px;
  }

  .homepage .patternsec .design_card p {
    font-size: 16px;
  }

  .homepage .patternsec .design_card .patternsec_p {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .homepage .banner_sec .banner_crown {
    padding-top: 150px;
  }

  .homepage .second_sec .king_img {
    /* transform: scale(2.0); */
    height: 533px;
    object-fit: cover;
    /* width: 100%; */
  }

  .homepage .second_sec .gradsec_img {
    /* display: none; */
  }

  .homepage .third_desc_sec .content_sec {
    margin-top: 30px;
  }

  .homepage .third_desc_sec .content_sec {
    height: 240px;
  }

  .homepage .third_desc_sec {
    padding-bottom: 260px;
  }

  .trained {
    display: block;
    bottom: 30px;
    width: 100%;
  }

}

@media screen and (min-width: 401px) and (max-width: 450px) {
  .homepage .banner_sec .leftflag_sec {
    bottom: 60px;
    width: 200px;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 90px;
    width: 140px
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 110px;
    width: 133px;
  }

  .homepage .banner_sec .leftflag_sec1 {
    bottom: 100px;
    width: 135px;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 40px;
    width: 140px;
  }

  .homepage .banner_sec .banner_crown {
    padding-top: 50%;
  }

  .trained {
    display: block;
    bottom: 30px;
    width: 100%;
  }

  .homepage .third_desc_sec .content_sec {
    padding-left: 0px;
    padding-right: 0px;
  }

  .homepage .second_sec .gradsec_img {
    /* display: none; */
  }

  .homepage .second_sec .king_img {
    /* transform: scale(2.0); */
    height: 533px;
    object-fit: cover;
    /* width: 100%; */
  }

  .homepage .patternsec .card_sec {
    padding-top: 20%;
  }

  .homepage .third_desc_sec {
    padding-bottom: 150px;
  }

  .homepage .card_sec_glow .img_glow {
    right: -2%;
    bottom: -25px;

  }

  .homepage .inner_container {
    max-width: 95% !important;
  }

  .homepage .patternsec .design_card p {
    font-size: 15px;
  }

  .homepage .design_card {
    padding: 13px;
    padding-top: 45px;
    padding-bottom: 40px;
    /* padding-left: 10px;
  padding-right: 10px; */
    width: 95%;
    margin: auto;

  }

  .homepage .patternsec .design_card .patternsec_p {
    margin-top: 20px;

  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 17px;
  }

  .patternsec .patternsec_p h1 {
    margin-bottom: 15px;
  }

  .charactor_sec .content_sec .sec_desc p {
    font-size: 16px !important;
    line-height: 25px !important;
    width: 97% !important;
    margin: auto;
  }

  .homepage .charactor_sec .content_sec .sec_title {}

  .homepage .charactor_sec .content_sec {
    padding: 0px !important;
  }

  .homepage .patternsec .card_sec {
    padding-bottom: 65%;
  }

}

@media screen and (min-width: 451px) and (max-width: 500px) {
  .homepage .banner_sec .leftflag_sec {
    bottom: 60px;
    width: 200px;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 100px;
    width: 140px
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 140px;
    width: 133px;
  }

  .homepage .banner_sec .leftflag_sec1 {
    bottom: 90px;
    width: 135px;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 40px;
    width: 140px;
  }

  .homepage .banner_sec .banner_crown {
    padding-top: 50%;
  }

  .trained {
    display: block;
    bottom: 30px;
    width: 100%;
  }

  .homepage .third_desc_sec .content_sec {
    padding-left: 5px;
    padding-right: 5px;
    height: 300px;
  }

  .homepage .second_sec .gradsec_img {
    /* display: none; */
    bottom: -10px;
  }

  .homepage .second_sec .king_img {
    /* transform: scale(2.0); */
    height: 533px;
    object-fit: cover;
    /* width: 100%; */
  }

  .homepage .patternsec .card_sec {
    padding-top: 20%;
  }

  .homepage .third_desc_sec {
    padding-bottom: 150px;
  }

  .homepage .card_sec_glow .img_glow {
    right: -2%;
    bottom: -25px;

  }

  .homepage .inner_container {
    max-width: 95% !important;
  }

  .homepage .patternsec .design_card p {
    font-size: 15px;
  }

  .homepage .patternsec .design_card .patternsec_p {
    margin-top: 20px;
  }

  .homepage .design_card {
    padding: 16px;
    padding-top: 10px;
    padding-bottom: 50px;
    /* padding-left: 10px;
  padding-right: 10px; */
    width: 95%;
    margin: auto;

  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 16px;
  }

  .patternsec .patternsec_p h1 {
    margin-bottom: 15px;
  }

  .charactor_sec .content_sec .sec_desc p {
    font-size: 16px !important;
    line-height: 25px !important;
    width: 97% !important;
    margin: auto;
  }

  .homepage .charactor_sec .content_sec .sec_title {}

  .homepage .charactor_sec .content_sec {
    padding: 0px !important;
  }

  .homepage .patternsec .card_sec {
    padding-bottom: 65%;
  }

  .homepage .relativing_abs .sec_desc {
    line-height: 30px;
    width: 90%;
    margin: auto;
  }

  .homepage .roadmap_sec .full_map {
    padding-left: 15px;
    padding-right: 15px;
  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    text-align: center;
  }

}

@media screen and (min-width: 501px) and (max-width: 549px) {
  .homepage .banner_sec .leftflag_sec {
    bottom: 60px;
    width: 200px;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 64px;
    width: 45%
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 140px;
    width: 133px;
  }

  .homepage .banner_sec .leftflag_sec1 {
    bottom: 50PX;
    width: 45%;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 40px;
    width: 140px;
  }

  .homepage .banner_sec .banner_crown {
    padding-top: 35%;
  }

  .trained {
    display: block;
    bottom: 30px;
    width: 100%;
  }

  .homepage .third_desc_sec .content_sec {
    padding-left: 5px;
    padding-right: 5px;
    height: 300px;
  }

  .homepage .second_sec .gradsec_img {
    /* display: none; */
    bottom: -15px;
  }

  .homepage .second_sec .king_img {
    /* transform: scale(2.0); */
    height: 533px;
    object-fit: cover;
    /* width: 100%; */
  }

  .homepage .patternsec .card_sec {
    padding-top: 20%;
  }

  .homepage .third_desc_sec {
    padding-bottom: 150px;
  }

  .homepage .card_sec_glow .img_glow {
    right: -2%;
    bottom: -25px;

  }

  .homepage .inner_container {
    max-width: 95% !important;
  }

  .homepage .patternsec .design_card p {
    font-size: 16px;
  }

  .homepage .design_card {
    padding: 13px;
    padding-top: 45px;
    padding-bottom: 70px;
    /* padding-left: 10px;
  padding-right: 10px; */

  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 16px;
  }

  .patternsec .patternsec_p h1 {
    margin-bottom: 15px;
  }

  .charactor_sec .content_sec .sec_desc p {
    font-size: 16px !important;
    line-height: 25px !important;
    width: 97% !important;
    margin: auto;
  }

  .homepage .charactor_sec .content_sec .sec_title {}

  .homepage .charactor_sec .content_sec {
    padding: 0px !important;
  }

  .homepage .patternsec .card_sec {
    padding-bottom: 65%;
  }

  .homepage .relativing_abs .sec_desc {
    line-height: 30px;
    width: 90%;
    margin: auto;
  }

  .homepage .roadmap_sec .full_map {
    padding-left: 15px;
    padding-right: 15px;
  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    text-align: center;
  }

}

@media screen and (min-width: 550px) and (max-width: 575px) {
  .homepage .banner_sec .leftflag_sec {
    bottom: 60px;
    width: 200px;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 64px;
    width: 45%
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 140px;
    width: 133px;
  }

  .homepage .banner_sec .leftflag_sec1 {
    bottom: 50PX;
    width: 45%;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 40px;
    width: 140px;
  }

  .homepage .banner_sec .banner_crown {
    padding-top: 35%;
  }

  .trained {
    display: block;
    bottom: 30px;
    width: 100%;
  }

  .homepage .third_desc_sec .content_sec {
    padding-left: 5px;
    padding-right: 5px;
    min-height: 350px;
    width: 80%;
    margin: auto;
    padding-top: 30px;
    margin-top: 30px;
  }

  .homepage .second_sec .gradsec_img {
    bottom: -20px;
  }

  .homepage .second_sec .king_img {
    /* transform: scale(2.0); */
    height: 533px;
    object-fit: cover;
    /* width: 100%; */
  }

  .homepage .patternsec .card_sec {
    padding-top: 20%;
  }

  .homepage .third_desc_sec {
    padding-bottom: 150px;
  }

  .homepage .card_sec_glow .img_glow {
    right: 11%;
    bottom: -25px;

  }

  .homepage .inner_container {
    max-width: 95% !important;
  }

  .homepage .patternsec .design_card p {
    font-size: 1.36rem;
    line-height: 35px;
    width: 84%;
    margin: auto;
    text-align: center;
  }

  .homepage .patternsec .design_card .patternsec_p {
    margin-top: 35px;
  }

  .homepage .design_card {
    padding: 13px;
    padding-top: 0px;
    padding-bottom: 70px;
    /* padding-left: 10px;
  padding-right: 10px; */

  }

  .faq_img {
    width: 75%;
    height: 100%;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 21px;
    line-height: 30px;
  }

  .patternsec .patternsec_p h1 {
    margin-bottom: 15px;
    text-align: center;
    font-size: 2rem;
  }

  .charactor_sec .content_sec .sec_desc p {
    font-size: 19px !important;
    line-height: 35px !important;
    width: 65% !important;
    margin: auto;
    margin-top: 10px;
  }

  .homepage .charactor_sec .content_sec .sec_title {}

  .homepage .charactor_sec .content_sec {
    padding: 0px !important;
  }

  .homepage .patternsec .card_sec {
    padding-bottom: 65%;
  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    text-align: center;
  }

  .homepage .roadmap_sec .full_map {
    padding-left: 15px;
    padding-right: 15px;
  }

  .homepage .charactor_sec {
    margin-top: -320px;
  }
}

@media screen and (min-width: 576px) and (max-width: 600px) {
  .homepage .banner_sec .leftflag_sec {
    bottom: 60px;
    width: 200px;
  }

  .homepage .design_card {
    width: 90%;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 75px !important;
    width: 44%;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 106px !important;
    width: 42%;
  }

  .homepage .banner_sec .leftflag_sec1 {
    bottom: 60px !important;
    width: 45%;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 100px !important;
    width: 45%;

  }

  .homepage .banner_sec .banner_crown {
    padding-top: 35%;
  }

  .trained {
    display: block;
    bottom: 30px;
    width: 100%;
  }

  .homepage .third_desc_sec .content_sec {
    padding-left: 40px;
    padding-right: 50px;
    min-height: 350px;
    width: 97%;
    margin: auto;
    padding-top: 30px;
    margin-top: 30px;
  }

  .homepage .second_sec .gradsec_img {
    bottom: -20px;
  }

  .homepage .second_sec .king_img {
    /* transform: scale(2.0); */
    height: 533px;
    object-fit: cover;
    /* width: 100%; */
  }

  .homepage .patternsec .card_sec {
    padding-top: 20%;
  }

  .homepage .third_desc_sec {
    padding-bottom: 150px;
  }

  .homepage .card_sec_glow .img_glow {
    right: 11%;
    bottom: -25px;

  }

  .homepage .inner_container {
    max-width: 95% !important;
  }

  .homepage .patternsec .design_card p {
    font-size: 1.36rem;
    line-height: 35px;
    width: 89%;
    margin: auto;
    text-align: center;
  }

  .homepage .patternsec .design_card .patternsec_p {
    margin-top: 65px;
  }

  .homepage .third_desc_sec {
    padding-bottom: 40px !important;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 1.36rem !important;
  }

  .homepage .design_card {
    padding: 13px;
    padding-top: 0px;
    padding-bottom: 70px;
    /* padding-left: 10px;
  padding-right: 10px; */

  }

  .faq_img {
    width: 75%;
    height: 100%;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 21px;
    line-height: 30px;
  }

  .patternsec .patternsec_p h1 {
    margin-bottom: 15px;
    text-align: center;
    font-size: 2rem;
  }

  .charactor_sec .content_sec .sec_desc p {
    font-size: 19px !important;
    line-height: 35px !important;
    width: 70% !important;
    margin: auto;
    margin-top: 10px;
  }

  .homepage .charactor_sec .content_sec .img_sec {
    width: 80%;
    margin: auto;
  }

  .homepage .training_sec .rightsec .sec_desc {
    width: 73%;
    margin: auto;
  }

  .homepage .charactor_sec .content_sec .sec_title {}

  .homepage .charactor_sec .content_sec {
    padding: 0px !important;
  }

  .homepage .patternsec .card_sec {
    padding-bottom: 0%;
  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    text-align: center;
  }

  .homepage .roadmap_sec .full_map {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 601px) and (max-width: 650px) {
  .homepage .banner_sec .leftflag_sec {
    bottom: 60px;
    width: 200px;
  }

  .homepage .design_card {
    width: 90%;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 75px !important;
    width: 44%;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 106px !important;
    width: 42%;
  }

  .homepage .banner_sec .leftflag_sec1 {
    bottom: 60px !important;
    width: 45%;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 100px !important;
    width: 45%;

  }

  .homepage .banner_sec .banner_crown {
    padding-top: 35%;
  }

  .trained {
    display: block;
    bottom: 30px;
    width: 100%;
  }

  .homepage .third_desc_sec .content_sec {
    padding-left: 40px;
    padding-right: 50px;
    min-height: 350px;
    width: 97%;
    margin: auto;
    padding-top: 30px;
    margin-top: 30px;
  }

  .homepage .second_sec .gradsec_img {
    bottom: -20px;
  }

  .homepage .second_sec .king_img {
    /* transform: scale(2.0); */
    height: 533px;
    object-fit: cover;
    /* width: 100%; */
  }

  .homepage .patternsec .card_sec {
    padding-top: 20%;
  }

  .homepage .third_desc_sec {
    padding-bottom: 150px;
  }

  .homepage .card_sec_glow .img_glow {
    right: 11%;
    bottom: -25px;

  }

  .homepage .inner_container {
    max-width: 95% !important;
  }

  .homepage .patternsec .design_card p {
    font-size: 1.36rem;
    line-height: 35px;
    width: 89%;
    margin: auto;
    text-align: center;
  }

  .homepage .patternsec .design_card .patternsec_p {
    margin-top: 65px;
  }

  .homepage .third_desc_sec {
    padding-bottom: 40px !important;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 1.36rem !important;
  }

  .homepage .design_card {
    padding: 13px;
    padding-top: 0px;
    padding-bottom: 70px;
    /* padding-left: 10px;
  padding-right: 10px; */

  }

  .faq_img {
    width: 75%;
    height: 100%;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 21px;
    line-height: 30px;
  }

  .patternsec .patternsec_p h1 {
    margin-bottom: 15px;
    text-align: center;
    font-size: 2rem;
  }

  .charactor_sec .content_sec .sec_desc p {
    font-size: 19px !important;
    line-height: 35px !important;
    width: 70% !important;
    margin: auto;
    margin-top: 10px;
  }

  .homepage .charactor_sec .content_sec .img_sec {
    width: 80%;
    margin: auto;
  }

  .homepage .training_sec .rightsec .sec_desc {
    width: 73%;
    margin: auto;
  }

  .homepage .charactor_sec .content_sec .sec_title {}

  .homepage .charactor_sec .content_sec {
    padding: 0px !important;
  }

  .homepage .patternsec .card_sec {
    padding-bottom: 0%;
  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    text-align: center;
  }

  .homepage .roadmap_sec .full_map {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 651px) and (max-width: 700px) {
  .homepage .banner_sec .leftflag_sec {
    bottom: 60px;
    width: 200px;
  }

  .homepage .design_card {
    width: 90%;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 75px !important;
    width: 44%;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 106px !important;
    width: 42%;
  }

  .homepage .banner_sec .leftflag_sec1 {
    bottom: 60px !important;
    width: 45%;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 100px !important;
    width: 45%;

  }

  .homepage .banner_sec .banner_crown {
    padding-top: 26%;
  }

  .trained {
    display: block;
    bottom: 30px;
    width: 100%;
  }

  .homepage .third_desc_sec .content_sec {
    padding-left: 40px;
    padding-right: 50px;
    min-height: 350px;
    width: 97%;
    margin: auto;
    padding-top: 30px;
    margin-top: 30px;
  }

  .homepage .second_sec .gradsec_img {
    bottom: -20px;
  }

  .homepage .second_sec .king_img {
    /* transform: scale(2.0); */
    height: 533px;
    object-fit: cover;
    /* width: 100%; */
  }

  .homepage .patternsec .card_sec {
    padding-top: 20%;
  }

  .homepage .third_desc_sec {
    padding-bottom: 150px;
  }

  .homepage .card_sec_glow .img_glow {
    right: 11%;
    bottom: -25px;

  }

  .homepage .inner_container {
    max-width: 95% !important;
  }

  .homepage .patternsec .design_card p {
    font-size: 1.36rem;
    line-height: 35px;
    width: 89.1%;
    margin: auto;
    text-align: center;
  }

  .homepage .patternsec .design_card .patternsec_p {
    margin-top: 65px;
  }

  .homepage .third_desc_sec {
    padding-bottom: 40px !important;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 1.36rem !important;
  }

  .homepage .design_card {
    padding: 13px;
    padding-top: 0px;
    padding-bottom: 70px;
    /* padding-left: 10px;
  padding-right: 10px; */

  }

  .faq_img {
    width: 75%;
    height: 100%;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 21px;
    line-height: 30px;
  }

  .patternsec .patternsec_p h1 {
    margin-bottom: 15px;
    text-align: center;
    font-size: 2rem;
  }

  .charactor_sec .content_sec .sec_desc p {
    font-size: 19px !important;
    line-height: 35px !important;
    width: 70% !important;
    margin: auto;
    margin-top: 10px;
  }

  .homepage .charactor_sec .content_sec .img_sec {
    width: 80%;
    margin: auto;
  }

  .homepage .training_sec .rightsec .sec_desc {
    width: 73%;
    margin: auto;
  }

  .homepage .charactor_sec .content_sec .sec_title {}

  .homepage .charactor_sec .content_sec {
    padding: 0px !important;
  }

  .homepage .patternsec .card_sec {
    padding-bottom: 0%;
  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    text-align: center;
  }

  .homepage .roadmap_sec .full_map {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 701px) and (max-width: 767px) {
  .homepage .banner_sec .leftflag_sec {
    bottom: 60px;
    width: 200px;
  }

  .homepage .design_card {
    width: 90%;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 75px !important;
    width: 44%;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 106px !important;
    width: 42%;
  }

  .homepage .banner_sec .leftflag_sec1 {
    bottom: 60px !important;
    width: 45%;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 100px !important;
    width: 45%;

  }

  .homepage .banner_sec .banner_crown {
    padding-top: 11%;
  }

  .homepage .charactor_sec .content_sec .sec_desc {
    width: 82.3% !important;
    margin: auto;
  }

  .trained {
    display: block;
    bottom: 30px;
    width: 100%;
  }

  .homepage .third_desc_sec .content_sec {
    padding-left: 40px;
    padding-right: 50px;
    min-height: 350px;
    width: 97%;
    margin: auto;
    padding-top: 30px;
    margin-top: 30px;
  }

  .homepage .second_sec .gradsec_img {
    bottom: -20px;
  }

  .homepage .second_sec .king_img {
    /* transform: scale(2.0); */
    height: 533px;
    object-fit: cover;
    /* width: 100%; */
  }

  .homepage .patternsec .card_sec {
    padding-top: 10%;
  }

  .homepage .third_desc_sec {
    padding-bottom: 150px;
  }

  .homepage .card_sec_glow .img_glow {
    right: 11%;
    bottom: -25px;

  }

  .homepage .inner_container {
    max-width: 95% !important;
  }

  .homepage .patternsec .design_card p {
    font-size: 1.36rem;
    line-height: 35px;
    width: 89.1%;
    margin: auto;
    text-align: center;
  }

  .homepage .patternsec .design_card .patternsec_p {
    margin-top: 65px;
  }

  .homepage .third_desc_sec {
    padding-bottom: 40px !important;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 1.36rem !important;
  }

  .homepage .design_card {
    padding: 13px;
    padding-top: 0px;
    padding-bottom: 70px;
    /* padding-left: 10px;
  padding-right: 10px; */

  }

  .faq_img {
    width: 65%;
    height: 100%;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 21px;
    line-height: 33px;
  }

  .patternsec .patternsec_p h1 {
    margin-bottom: 15px;
    text-align: center;
    font-size: 2rem;
  }

  .charactor_sec .content_sec .sec_desc p {
    font-size: 19px !important;
    line-height: 35px !important;
    width: 70% !important;
    margin: auto;
    margin-top: 10px;
  }

  .homepage .charactor_sec .content_sec .img_sec {
    width: 80%;
    margin: auto;
  }

  .homepage .training_sec .rightsec .sec_desc {
    width: 72%;
    margin: auto;
  }

  .homepage .charactor_sec .content_sec .sec_title {}

  .homepage .charactor_sec .content_sec {
    padding: 0px !important;
  }

  .homepage .patternsec .card_sec {
    padding-bottom: 0%;
  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    text-align: center;
  }

  .homepage .roadmap_sec .full_map {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 768px)and (max-width:991px) {
  .patternsec .design_card .patternsec_p {
    text-align: center;
    margin-top: 80px;

  }

  .patternsec .design_card .patternsec_p p {
    font-size: 21px;
  }

  .homepage .design_card {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 30px;
    padding-bottom: 75px;
  }

  .patternsec .patternsec_p h1 {
    font-size: 2rem;

  }

  .homepage .charactor_sec .content_sec p {
    width: 85%;
    margin: auto;
  }

  .homepage .charactor_sec .content_sec .img_sec {
    width: 90%;
    margin: auto;
  }

  .homepage .training_sec .rightsec {
    width: 90%;
    margin: auto;
    text-align: center;

  }
}

@media screen and (min-width: 768px) and (max-width:800px) {
  .homepage .banner_sec .leftflag_sec {
    bottom: 60px;
    width: 200px;
  }

  .homepage .design_card {
    width: 90%;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 0px !important;
    width: 44%;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 60px !important;
    width: 42%;
  }

  .homepage .banner_sec .leftflag_sec1 {
    bottom: 0px !important;
    width: 45%;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 60px !important;
    width: 45%;

  }

  .homepage .banner_sec .banner_crown {
    padding-top: 11%;
  }

  .homepage .charactor_sec .content_sec .sec_desc {
    width: 82.3% !important;
    margin: auto;
  }

  .trained {
    display: block;
    bottom: 30px;
    width: 100%;
  }

  .homepage .third_desc_sec .content_sec {
    padding-left: 40px;
    padding-right: 50px;
    min-height: 350px;
    width: 96%;
    margin: auto;
    padding-top: 30px;
    margin-top: 30px;
  }

  .homepage .second_sec .gradsec_img {
    bottom: -20px;
  }

  .homepage .second_sec .king_img {
    /* transform: scale(2.0); */
    height: 533px;
    object-fit: cover;
    /* width: 100%; */
  }

  .homepage .patternsec .card_sec {
    padding-top: 10%;
  }

  .homepage .third_desc_sec {
    padding-bottom: 150px;
  }

  .homepage .card_sec_glow .img_glow {
    right: 11%;
    bottom: -25px;

  }

  .homepage .inner_container {
    max-width: 95% !important;
  }

  .homepage .patternsec .design_card p {
    font-size: 1.36rem;
    line-height: 35px;
    width: 89.15%;
    margin: auto;
    text-align: center;
  }

  .homepage .patternsec .design_card .patternsec_p {
    margin-top: 85px;
  }

  .homepage .charactor_sec {
    padding-top: 60px;
  }

  .homepage .third_desc_sec {
    padding-bottom: 40px !important;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 1.36rem !important;
  }

  .homepage .design_card {
    padding: 13px;
    padding-top: 0px;
    padding-bottom: 70px;
    /* padding-left: 10px;
  padding-right: 10px; */

  }

  .faq_img {
    width: 65%;
    height: 100%;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 21px;
    line-height: 33px;
  }

  .patternsec .patternsec_p h1 {
    margin-bottom: 15px;
    text-align: center;
    font-size: 2rem;
  }

  .charactor_sec .content_sec .sec_desc p {
    font-size: 19px !important;
    line-height: 35px !important;
    width: 70% !important;
    margin: auto;
    margin-top: 10px;
  }

  .homepage .charactor_sec .content_sec .img_sec {
    width: 80%;
    margin: auto;
  }

  .homepage .training_sec .rightsec .sec_desc {
    width: 79%;
    margin: auto;
  }

  .homepage .banner_sec .banner_crown {
    width: 60% !important;
  }


  .homepage .charactor_sec .content_sec {
    padding: 0px !important;
  }

  .homepage .patternsec .card_sec {
    padding-bottom: 0%;
  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    text-align: center;
  }

  .homepage .roadmap_sec .full_map {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 801px) and (max-width:849px) {
  .homepage .banner_sec .leftflag_sec {
    bottom: 60px;
    width: 200px;
  }

  .homepage .design_card {
    width: 90%;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 0px !important;
    width: 44%;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 60px !important;
    width: 42%;
  }

  .homepage .banner_sec .leftflag_sec1 {
    bottom: 0px !important;
    width: 45%;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 60px !important;
    width: 45%;

  }

  .homepage .banner_sec .banner_crown {
    padding-top: 11%;
  }

  .homepage .charactor_sec .content_sec .sec_desc {
    width: 82.3% !important;
    margin: auto;
  }

  .trained {
    display: block;
    bottom: 30px;
    width: 100%;
  }

  .homepage .third_desc_sec .content_sec {
    padding-left: 40px;
    padding-right: 50px;
    min-height: 350px;
    width: 96%;
    margin: auto;
    padding-top: 30px;
    margin-top: 30px;
  }

  .homepage .second_sec .gradsec_img {
    bottom: -20px;
  }

  .homepage .second_sec .king_img {
    /* transform: scale(2.0); */
    height: 533px;
    object-fit: cover;
    /* width: 100%; */
  }

  .homepage .patternsec .card_sec {
    padding-top: 10%;
  }

  .homepage .third_desc_sec {
    padding-bottom: 150px;
  }

  .homepage .card_sec_glow .img_glow {
    right: 11%;
    bottom: -25px;

  }

  .homepage .inner_container {
    max-width: 95% !important;
  }

  .homepage .patternsec .design_card p {
    font-size: 1.36rem;
    line-height: 35px;
    width: 89.5%;
    margin: auto;
    text-align: center;
  }

  .homepage .patternsec .design_card .patternsec_p {
    margin-top: 85px;
  }

  .homepage .charactor_sec {
    padding-top: 60px;
    background-size: 100% calc(100% + 220px);
  }

  .homepage .third_desc_sec {
    padding-bottom: 40px !important;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 1.36rem !important;
  }

  .homepage .design_card {
    padding: 13px;
    padding-top: 0px;
    padding-bottom: 70px;
    /* padding-left: 10px;
  padding-right: 10px; */

  }

  .faq_img {
    width: 65%;
    height: 100%;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 21px;
    line-height: 33px;
  }

  .patternsec .patternsec_p h1 {
    margin-bottom: 15px;
    text-align: center;
    font-size: 2rem;
  }

  .charactor_sec .content_sec .sec_desc p {
    font-size: 19px !important;
    line-height: 35px !important;
    width: 70% !important;
    margin: auto;
    margin-top: 10px;
  }

  .homepage .charactor_sec .content_sec .img_sec {
    width: 80%;
    margin: auto;
  }

  .homepage .training_sec .rightsec .sec_desc {
    width: 79%;
    margin: auto;
  }

  .homepage .banner_sec .banner_crown {
    width: 60% !important;
  }


  .homepage .charactor_sec .content_sec {
    padding: 0px !important;
  }

  .homepage .patternsec .card_sec {
    padding-bottom: 0%;
  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    text-align: center;
  }

  .homepage .roadmap_sec .full_map {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 850px) and (max-width:900px) {
  .homepage .banner_sec .leftflag_sec {
    bottom: 60px;
    width: 200px;
  }

  .homepage .design_card {
    width: 80%;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 0px !important;
    width: 44%;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 60px !important;
    width: 42%;
  }

  .homepage .banner_sec .leftflag_sec1 {
    bottom: 0px !important;
    width: 45%;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 60px !important;
    width: 45%;

  }

  .homepage .banner_sec .banner_crown {
    padding-top: 20%;
  }

  .homepage .charactor_sec .content_sec .sec_desc {
    width: 82.3% !important;
    margin: auto;
  }

  .trained {
    display: block;
    bottom: 30px;
    width: 100%;
  }

  .homepage .third_desc_sec .content_sec {
    padding-left: 40px;
    padding-right: 50px;
    min-height: 350px;
    width: 77%;
    margin: auto;
    padding-top: 40px;
    margin-top: 30px;
  }

  .homepage .second_sec .gradsec_img {
    bottom: -45px;
  }

  .homepage .second_sec .king_img {
    width: 100%;
    height: 100%;
  }

  .homepage .patternsec .card_sec {
    padding-top: 10%;
  }

  .homepage .third_desc_sec {
    padding-bottom: 150px;
  }

  .homepage .card_sec_glow .img_glow {
    right: 11%;
    bottom: -25px;

  }

  .homepage .inner_container {
    max-width: 95% !important;
  }

  .homepage .patternsec .design_card .patternsec_p {

    width: 80%;
    margin: auto;
  }

  .homepage .patternsec .design_card p {
    font-size: 1.36rem;
    line-height: 35px;
    text-align: left;
  }

  .homepage .patternsec .design_card .patternsec_p {
    margin-top: 95px;
  }

  .homepage .charactor_sec {
    padding-top: 70px;
    background-size: 100% calc(100% + 220px);
  }

  .homepage .third_desc_sec {
    padding-bottom: 40px !important;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 1.36rem !important;
  }

  .homepage .design_card {
    padding: 13px;
    padding-top: 0px;
    padding-bottom: 70px;
    /* padding-left: 10px;
  padding-right: 10px; */

  }

  .faq_img {
    width: 65% !important;
    height: 100%;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 21px;
    line-height: 33px;
  }

  .patternsec .patternsec_p h1 {
    margin-bottom: 15px;
    text-align: left;
    font-size: 2rem;
  }

  .charactor_sec .content_sec .sec_desc p {
    font-size: 19px !important;
    line-height: 35px !important;
    width: 70% !important;
    margin: auto;
    margin-top: 10px;
  }

  .homepage .charactor_sec .content_sec .img_sec {
    width: 80%;
    margin: auto;
  }

  .homepage .training_sec .rightsec .sec_desc {
    width: 79%;
    margin: auto;
  }

  .homepage .banner_sec .banner_crown {
    width: 40% !important;
  }


  .homepage .charactor_sec .content_sec {
    padding: 0px !important;
  }

  .homepage .patternsec .card_sec {
    padding-bottom: 0%;
  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    text-align: center;
  }

  .homepage .roadmap_sec .full_map {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 901px) and (max-width:950px) {
  .homepage .banner_sec .leftflag_sec {
    bottom: 60px;
    width: 200px;
  }

  .homepage .design_card {
    width: 80%;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 0px !important;
    width: 34%;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 60px !important;
    width: 42%;
  }

  .homepage .banner_sec .leftflag_sec1 {
    bottom: 0px !important;
    width: 35%;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 60px !important;
    width: 35%;

  }

  .homepage .banner_sec .banner_crown {
    padding-top: 20%;
  }

  .homepage .charactor_sec .content_sec .sec_desc {
    width: 82.3% !important;
    margin: auto;
  }

  .trained {
    display: block;
    bottom: 30px;
    width: 100%;
  }

  .homepage .third_desc_sec .content_sec {
    padding-left: 40px;
    padding-right: 50px;
    min-height: 350px;
    width: 77%;
    margin: auto;
    padding-top: 40px;
    margin-top: 30px;
  }

  .homepage .second_sec .gradsec_img {
    bottom: -45px;
  }

  .homepage .second_sec .king_img {
    width: 100%;
    height: 100%;
  }

  .homepage .patternsec .card_sec {
    padding-top: 10%;
  }

  .homepage .third_desc_sec {
    padding-bottom: 150px;
  }

  .homepage .card_sec_glow .img_glow {
    right: 11%;
    bottom: -25px;

  }

  .homepage .inner_container {
    max-width: 95% !important;
  }

  .homepage .patternsec .design_card .patternsec_p {

    width: 80%;
    margin: auto;
  }

  .homepage .patternsec .design_card p {
    font-size: 1.36rem;
    line-height: 35px;
    text-align: left;
  }

  .homepage .patternsec .design_card .patternsec_p {
    margin-top: 95px;
  }

  .homepage .charactor_sec {
    padding-top: 70px;
    background-size: 100% calc(100% + 220px);
  }

  .homepage .third_desc_sec {
    padding-bottom: 40px !important;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 1.36rem !important;
  }

  .homepage .design_card {
    padding: 13px;
    padding-top: 0px;
    padding-bottom: 70px;
    /* padding-left: 10px;
  padding-right: 10px; */

  }

  .faq_img {
    width: 50% !important;
    height: 100%;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 21px;
    line-height: 33px;
  }

  .patternsec .patternsec_p h1 {
    margin-bottom: 15px;
    text-align: left;
    font-size: 2rem;
  }

  .charactor_sec .content_sec .sec_desc p {
    font-size: 19px !important;
    line-height: 35px !important;
    width: 70% !important;
    margin: auto;
    margin-top: 10px;
  }

  .homepage .charactor_sec .content_sec .img_sec {
    width: 70%;
    margin: auto;
  }

  .homepage .training_sec .rightsec .sec_desc {
    width: 79%;
    margin: auto;
  }

  .homepage .banner_sec .banner_crown {
    width: 40% !important;
  }


  .homepage .charactor_sec .content_sec {
    padding: 0px !important;
  }

  .homepage .patternsec .card_sec {
    padding-bottom: 0%;
  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    text-align: center;
  }

  .homepage .roadmap_sec .full_map {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width:991px) {
  .homepage .patternsec .design_card {
    background: none;
    position: relative;
    overflow: hidden;
  }

  .homepage .patternsec .design_card::before {
    position: absolute;
    content: "";
    background: url('../images/new_img/ptsec_border.webp');
    height: 100%;
    width: 100%;
    left: 0px;
    background-size: 100% auto;
    background-repeat: no-repeat;

  }

  .homepage .patternsec .design_card::after {
    position: absolute;
    content: "";
    background: url('../images/new_img/ptsec_border.webp');
    height: 100%;
    width: 100%;
    left: 0px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    transform: rotateX(180deg);
    bottom: 0px;
  }
}

@media screen and (min-width: 951px) and (max-width:991px) {
  .homepage .banner_sec .leftflag_sec {
    bottom: 60px;
    width: 200px;
  }

  .homepage .design_card {
    width: 80%;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 0px !important;
    width: 34%;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 60px !important;
    width: 42%;
  }

  .homepage .banner_sec .leftflag_sec1 {
    bottom: 0px !important;
    width: 35%;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 60px !important;
    width: 35%;

  }

  .homepage .banner_sec .banner_crown {
    padding-top: 15%;
  }

  .homepage .charactor_sec .content_sec .sec_desc {
    width: 82.3% !important;
    margin: auto;
  }

  .trained {
    display: block;
    bottom: 30px;
    width: 100%;
  }

  .homepage .third_desc_sec .content_sec {
    padding-left: 40px;
    padding-right: 50px;
    min-height: 350px;
    width: 76%;
    margin: auto;
    padding-top: 40px;
    margin-top: 30px;
  }

  .homepage .second_sec .gradsec_img {
    bottom: -45px;
  }

  .homepage .second_sec .king_img {
    width: 100%;
    height: 100%;
  }

  .homepage .patternsec .card_sec {
    padding-top: 10%;
  }

  .homepage .third_desc_sec {
    padding-bottom: 150px;
  }

  .homepage .card_sec_glow .img_glow {
    right: 11%;
    bottom: -25px;

  }

  .homepage .inner_container {
    max-width: 95% !important;
  }

  .homepage .patternsec .design_card .patternsec_p {

    width: 84.5%;
    margin: auto;
  }

  .homepage .patternsec .design_card p {
    font-size: 1.36rem;
    line-height: 35px;
    text-align: left;
  }

  .homepage .patternsec .design_card .patternsec_p {
    margin-top: 105px;
  }

  .homepage .charactor_sec {
    padding-top: 70px;
    background-size: 100% calc(100% + 220px);
  }

  .homepage .third_desc_sec {
    padding-bottom: 40px !important;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 1.36rem !important;
  }

  .homepage .design_card {
    padding: 13px;
    padding-top: 0px;
    padding-bottom: 70px;
    /* padding-left: 10px;
  padding-right: 10px; */

  }

  .faq_img {
    width: 50% !important;
    height: 100%;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 21px;
    line-height: 33px;
  }

  .patternsec .patternsec_p h1 {
    margin-bottom: 15px;
    text-align: left;
    font-size: 2rem;
  }

  .charactor_sec .content_sec .sec_desc p {
    font-size: 19px !important;
    line-height: 35px !important;
    width: 70% !important;
    margin: auto;
    margin-top: 10px;
  }

  .homepage .charactor_sec .content_sec .img_sec {
    width: 70%;
    margin: auto;
  }

  .homepage .training_sec .rightsec .sec_desc {
    width: 79%;
    margin: auto;
  }

  .homepage .banner_sec .banner_crown {
    width: 35% !important;
  }


  .homepage .charactor_sec .content_sec {
    padding: 0px !important;
  }

  .homepage .patternsec .card_sec {
    padding-bottom: 0%;
  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    text-align: center;
  }

  .homepage .roadmap_sec .full_map {
    padding-left: 15px;
    padding-right: 15px;
  }

  .homepage .patternsec .design_card {
    background: none;
    position: relative;
    overflow: hidden;
  }

  .homepage .patternsec .design_card::before {
    position: absolute;
    content: "";
    background: url('../images/new_img/ptsec_border.webp');
    height: 100%;
    width: 100%;
    left: 0px;
    background-size: 100% auto;
    background-repeat: no-repeat;

  }

  .homepage .patternsec .design_card::after {
    position: absolute;
    content: "";
    background: url('../images/new_img/ptsec_border.webp');
    height: 100%;
    width: 100%;
    left: 0px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    transform: rotateX(180deg);
    bottom: 0px;
  }
}

@media screen and (min-width: 992px) and (max-width:1050px) {
  .homepage .banner_sec .leftflag_sec {
    bottom: 60px;
    width: 200px;
  }

  .homepage .design_card {
    width: 80%;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 0px !important;
    width: 34%;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 60px !important;
    width: 42%;
  }

  .homepage .banner_sec .leftflag_sec1 {
    bottom: 0px !important;
    width: 35%;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 60px !important;
    width: 35%;

  }

  .homepage .banner_sec .banner_crown {
    padding-top: 15%;
  }

  .homepage .charactor_sec .content_sec .sec_desc {
    width: 82.3% !important;
    margin: auto;
  }

  .trained {
    display: block;
    bottom: 30px;
    width: 100%;
  }

  .homepage .third_desc_sec .content_sec {
    padding-left: 40px;
    padding-right: 50px;
    min-height: 350px;
    width: 76%;
    margin: auto;
    padding-top: 40px;
    margin-top: 30px;
  }

  .homepage .second_sec .gradsec_img {
    bottom: -53px;
  }

  .homepage .second_sec .king_img {
    width: 100%;
    height: 100%;
  }

  .homepage .patternsec .card_sec {
    padding-top: 10%;
  }

  .homepage .third_desc_sec {
    padding-bottom: 150px;
  }

  .homepage .card_sec_glow .img_glow {
    right: 18%;
    bottom: -25px;

  }

  .homepage .inner_container {
    max-width: 95% !important;
  }

  .homepage .patternsec .design_card .patternsec_p {

    width: 84.5%;
    margin: auto;
  }

  .homepage .patternsec .design_card p {
    font-size: 1.36rem;
    line-height: 35px;
    text-align: left;
  }

  .homepage .patternsec .design_card .patternsec_p {
    margin-top: 105px;
  }

  .homepage .charactor_sec {
    padding-top: 70px;
    background-size: 100% calc(100% + 220px);
  }

  .homepage .third_desc_sec {
    padding-bottom: 40px !important;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 1.36rem !important;
  }

  .homepage .design_card {
    padding: 13px;
    padding-top: 0px;
    padding-bottom: 70px;
    margin-bottom: 35px;
    /* padding-left: 10px;
  padding-right: 10px; */

  }

  .faq_img {
    width: 50% !important;
    height: 100%;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 21px;
    line-height: 33px;
  }

  .patternsec .patternsec_p h1 {
    margin-bottom: 15px;
    text-align: left;
    font-size: 2rem;
  }

  .charactor_sec .content_sec .sec_desc p {
    font-size: 19px !important;
    line-height: 35px !important;
    width: 70% !important;
    margin: auto;
    margin-top: 10px;
  }

  .homepage .charactor_sec .content_sec .img_sec {
    width: 70%;
    margin: auto;
  }

  .homepage .training_sec .rightsec .sec_desc {
    width: 83%;
  }

  .homepage .banner_sec .banner_crown {
    width: 35% !important;
  }


  .homepage .charactor_sec .content_sec {
    padding: 0px !important;
  }

  .homepage .patternsec .card_sec {
    padding-bottom: 0%;
  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    text-align: center;
  }

  .homepage .roadmap_sec .full_map {
    padding-left: 15px;
    padding-right: 15px;
  }

  .homepage .patternsec .design_card {
    background: none;
    position: relative;
    overflow: hidden;
  }

  .homepage .patternsec .design_card::before {
    position: absolute;
    content: "";
    background: url('../images/new_img/ptsec_border.webp');
    height: 100%;
    width: 100%;
    left: 0px;
    background-size: 100% auto;
    background-repeat: no-repeat;

  }

  .homepage .patternsec .design_card::after {
    position: absolute;
    content: "";
    background: url('../images/new_img/ptsec_border.webp');
    height: 100%;
    width: 100%;
    left: 0px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    transform: rotateX(180deg);
    bottom: 0px;
  }
}

@media screen and (min-width: 1051px) and (max-width:1100px) {
  .homepage .banner_sec .leftflag_sec {
    bottom: 60px;
    width: 200px;
  }

  .homepage .design_card {
    width: 80%;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 0px !important;
    width: 34%;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 60px !important;
    width: 42%;
  }

  .homepage .banner_sec .leftflag_sec1 {
    bottom: 0px !important;
    width: 35%;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 60px !important;
    width: 35%;

  }

  .homepage .banner_sec .banner_crown {
    padding-top: 10%;
  }

  .homepage .charactor_sec .content_sec .sec_desc {
    width: 82.6% !important;
    margin: auto;
  }

  .trained {
    display: block;
    bottom: 30px;
    width: 100%;
  }

  .homepage .third_desc_sec .content_sec {
    padding-left: 40px;
    padding-right: 50px;
    min-height: 350px;
    width: 76%;
    margin: auto;
    padding-top: 40px;
    margin-top: 30px;
  }

  .homepage .second_sec .gradsec_img {
    bottom: -53px;
  }

  .homepage .second_sec .king_img {
    width: 100%;
    height: 100%;
  }

  .homepage .patternsec .card_sec {
    padding-top: 10%;
  }

  .homepage .third_desc_sec {
    padding-bottom: 150px;
  }

  .homepage .card_sec_glow .img_glow {
    right: 18%;
    bottom: -25px;

  }

  .homepage .inner_container {
    max-width: 95% !important;
  }

  .homepage .patternsec .design_card .patternsec_p {

    width: 84.5%;
    margin: auto;
  }

  .homepage .patternsec .design_card p {
    font-size: 1.36rem;
    line-height: 35px;
    text-align: left;
  }

  .homepage .patternsec .design_card .patternsec_p {
    margin-top: 105px;
  }

  .homepage .charactor_sec {
    padding-top: 70px;
    background-size: 100% calc(100% + 220px);
  }

  .homepage .third_desc_sec {
    padding-bottom: 40px !important;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 1.36rem !important;
  }

  .homepage .design_card {
    padding: 13px;
    padding-top: 0px;
    padding-bottom: 70px;
    margin-bottom: 35px;
    /* padding-left: 10px;
  padding-right: 10px; */

  }

  .faq_img {
    width: 50% !important;
    height: 100%;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 21px;
    line-height: 33px;
  }

  .patternsec .patternsec_p h1 {
    margin-bottom: 15px;
    text-align: left;
    font-size: 2rem;
  }

  .charactor_sec .content_sec .sec_desc p {
    font-size: 19px !important;
    line-height: 35px !important;
    width: 70% !important;
    margin: auto;
    margin-top: 10px;
  }

  .homepage .charactor_sec .content_sec .img_sec {
    width: 70%;
    margin: auto;
  }

  .homepage .training_sec .rightsec .sec_desc {
    width: 83%;
  }

  .homepage .banner_sec .banner_crown {
    width: 35% !important;
  }


  .homepage .charactor_sec .content_sec {
    padding: 0px !important;
  }

  .homepage .patternsec .card_sec {
    padding-bottom: 0%;
  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    text-align: center;
  }

  .homepage .roadmap_sec .full_map {
    padding-left: 15px;
    padding-right: 15px;
  }

  .homepage .patternsec .design_card {
    background: none;
    position: relative;
    overflow: hidden;
  }

  .homepage .patternsec .design_card::before {
    position: absolute;
    content: "";
    background: url('../images/new_img/ptsec_border.webp');
    height: 100%;
    width: 100%;
    left: 0px;
    background-size: 100% auto;
    background-repeat: no-repeat;

  }

  .homepage .patternsec .design_card::after {
    position: absolute;
    content: "";
    background: url('../images/new_img/ptsec_border.webp');
    height: 100%;
    width: 100%;
    left: 0px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    transform: rotateX(180deg);
    bottom: 0px;
  }
}

@media screen and (min-width: 1101px) and (max-width:1150px) {
  .homepage .banner_sec .leftflag_sec {
    bottom: 60px;
    width: 200px;
  }

  .homepage .design_card {
    width: 80%;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 0px !important;
    width: 34%;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 60px !important;
    width: 42%;
  }

  .homepage .banner_sec .leftflag_sec1 {
    bottom: 0px !important;
    width: 35%;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 60px !important;
    width: 35%;

  }

  .homepage .banner_sec .banner_crown {
    padding-top: 10%;
  }

  .homepage .charactor_sec .content_sec .sec_desc {
    width: 82.6% !important;
    margin: auto;
  }

  .trained {
    display: block;
    bottom: 30px;
    width: 100%;
  }

  .homepage .third_desc_sec .content_sec {
    padding-left: 40px;
    padding-right: 50px;
    min-height: 350px;
    width: 75%;
    margin: auto;
    padding-top: 40px;
    margin-top: 30px;
  }

  .homepage .second_sec .gradsec_img {
    bottom: -53px;
  }

  .homepage .second_sec .king_img {
    width: 100%;
    height: 100%;
  }

  .homepage .patternsec .card_sec {
    padding-top: 10%;
  }

  .homepage .third_desc_sec {
    padding-bottom: 150px;
  }

  .homepage .card_sec_glow .img_glow {
    right: 21%;
    bottom: -65px;

  }

  .homepage .inner_container {
    max-width: 95% !important;
  }

  .homepage .patternsec .design_card .patternsec_p {
    margin: auto;
    padding-left: 55px;
    padding-right: 55px;
  }

  .homepage .patternsec .design_card p {
    font-size: 1.36rem;
    line-height: 35px;
    text-align: left;
  }

  .homepage .patternsec .design_card .patternsec_p {
    margin-top: 105px;
  }

  .homepage .charactor_sec {
    padding-top: 70px;
    background-size: 100% calc(100% + 220px);
  }

  .homepage .third_desc_sec {
    padding-bottom: 40px !important;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 1.36rem !important;
  }

  .homepage .design_card {
    padding: 13px;
    padding-top: 0px;
    padding-bottom: 70px;
    margin-bottom: 35px;
    /* padding-left: 10px;
  padding-right: 10px; */

  }

  .faq_img {
    width: 50% !important;
    height: 100%;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 21px;
    line-height: 33px;
  }

  .patternsec .patternsec_p h1 {
    margin-bottom: 15px;
    text-align: left;
    font-size: 2rem;
  }

  .charactor_sec .content_sec .sec_desc p {
    font-size: 19px !important;
    line-height: 35px !important;
    width: 70% !important;
    margin: auto;
    margin-top: 10px;
  }

  .homepage .charactor_sec .content_sec .img_sec {
    width: 70%;
    margin: auto;
  }

  .homepage .training_sec .rightsec .sec_desc {
    width: 83%;
  }

  .homepage .banner_sec .banner_crown {
    width: 35% !important;
  }


  .homepage .charactor_sec .content_sec {
    padding: 0px !important;
  }

  .homepage .patternsec .card_sec {
    padding-bottom: 0%;
  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    text-align: center;
  }

  .homepage .roadmap_sec .full_map {
    padding-left: 15px;
    padding-right: 15px;
  }

  .homepage .patternsec .design_card {
    background: none;
    position: relative;
    overflow: hidden;
  }

  .homepage .patternsec .design_card::before {
    position: absolute;
    content: "";
    background: url('../images/new_img/ptsec_border.webp');
    height: 100%;
    width: 100%;
    left: 0px;
    background-size: 100% auto;
    background-repeat: no-repeat;

  }

  .homepage .patternsec .design_card::after {
    position: absolute;
    content: "";
    background: url('../images/new_img/ptsec_border.webp');
    height: 100%;
    width: 100%;
    left: 0px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    transform: rotateX(180deg);
    bottom: 0px;
  }
}

@media screen and (min-width: 1151px) and (max-width:1199px) {
  .homepage .banner_sec .leftflag_sec {
    bottom: 60px;
    width: 200px;
  }

  .homepage .design_card {
    width: 80%;
  }

  .homepage .banner_sec .rightflag_sec1 {
    bottom: 0px !important;
    width: 34%;
  }

  .homepage .banner_sec .leftflag_sec {
    bottom: 60px !important;
    width: 42%;
  }

  .homepage .banner_sec .leftflag_sec1 {
    bottom: 0px !important;
    width: 35%;
  }

  .homepage .banner_sec .rightflag_sec {
    bottom: 60px !important;
    width: 35%;

  }

  .homepage .banner_sec .banner_crown {
    padding-top: 10%;
  }

  .homepage .charactor_sec .content_sec .sec_desc {
    width: 82.6% !important;
    margin: auto;
  }

  .trained {
    display: block;
    bottom: 30px;
    width: 100%;
  }

  .homepage .third_desc_sec .content_sec {
    padding-left: 40px;
    padding-right: 50px;
    min-height: 350px;
    width: 76%;
    margin: auto;
    padding-top: 40px;
    margin-top: 30px;
  }

  .homepage .second_sec .gradsec_img {
    bottom: -53px;
  }

  .homepage .second_sec .king_img {
    width: 100%;
    height: 100%;
  }

  .homepage .patternsec .card_sec {
    padding-top: 10%;
  }

  .homepage .third_desc_sec {
    padding-bottom: 150px;
  }

  .homepage .card_sec_glow .img_glow {
    right: 21%;
    bottom: -65px;

  }

  .homepage .inner_container {
    max-width: 95% !important;
  }

  .homepage .patternsec .design_card .patternsec_p {
    margin: auto;
    padding-left: 63px;
    padding-right: 57px;
  }

  .homepage .patternsec .design_card p {
    font-size: 1.36rem;
    line-height: 35px;
    text-align: left;
  }

  .homepage .patternsec .design_card .patternsec_p {
    margin-top: 105px;
  }

  .homepage .charactor_sec {
    padding-top: 70px;
    background-size: 100% calc(100% + 220px);
  }

  .homepage .third_desc_sec {
    padding-bottom: 40px !important;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 1.36rem !important;
  }

  .homepage .design_card {
    padding: 13px;
    padding-top: 0px;
    padding-bottom: 70px;
    margin-bottom: 35px;
    /* padding-left: 10px;
  padding-right: 10px; */

  }

  .faq_img {
    width: 50% !important;
    height: 100%;
  }

  .homepage .third_desc_sec .content_sec p {
    font-size: 21px;
    line-height: 33px;
  }

  .patternsec .patternsec_p h1 {
    margin-bottom: 15px;
    text-align: left;
    font-size: 2rem;
  }

  .charactor_sec .content_sec .sec_desc p {
    font-size: 19px !important;
    line-height: 35px !important;
    width: 70% !important;
    margin: auto;
    margin-top: 10px;
  }

  .homepage .charactor_sec .content_sec .img_sec {
    width: 70%;
    margin: auto;
  }

  .homepage .training_sec .rightsec .sec_desc {
    width: 83%;
  }

  .homepage .banner_sec .banner_crown {
    width: 35% !important;
  }


  .homepage .charactor_sec .content_sec {
    padding: 0px !important;
  }

  .homepage .patternsec .card_sec {
    padding-bottom: 0%;
  }

  .homepage .roadmap_sec .mapsec .sec_desc {
    text-align: center;
  }

  .homepage .roadmap_sec .full_map {
    padding-left: 15px;
    padding-right: 15px;
  }

  .homepage .patternsec .design_card {
    background: none;
    position: relative;
    overflow: hidden;
  }

  .homepage .patternsec .design_card::before {
    position: absolute;
    content: "";
    background: url('../images/new_img/ptsec_border.webp');
    height: 100%;
    width: 100%;
    left: 0px;
    background-size: 100% auto;
    background-repeat: no-repeat;

  }

  .homepage .patternsec .design_card::after {
    position: absolute;
    content: "";
    background: url('../images/new_img/ptsec_border.webp');
    height: 100%;
    width: 100%;
    left: 0px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    transform: rotateX(180deg);
    bottom: 0px;
  }
}

@media screen and (min-width:992px) and (max-width:1199px) {
  .homepage .banner_sec .banner_crown {
    top: -5px;
  }
}

@media screen and (min-width:1351px) and (max-width:1400px) {
  .homepage .design_card {
    width: 82%;
  }

  .store_pages .store_card_sec {
    padding: 100px 45px 80px 45px !important;
  }
}

@media screen and (min-width:1401px) and (max-width:1450px) {
  .homepage .design_card {
    width: 81%;
  }
}

@media screen and (min-width:1451px) {
  .homepage .design_card {
    width: 70%;
  }

  .homepage p {
    font-weight: 400;
  }

  .patternsec .patternsec_p h1 {
    font-size: 2rem !important;
  }

  .training_sec .rightsec .averia_font p {
    font-size: 1.36rem !important;
  }

  .homepage .charactor_sec .content_sec .img_sec {
    margin-top: 75px;
  }
}

.loading_text {
  color: #fff;
}

@media screen and (min-width: 1920px) {
  .homepage .second_sec .gradsec_img {
    bottom: -7%;
  }
}

@media screen and (min-width: 3500px) {
  .homepage .second_sec .gradsec_img {
    bottom: -4%;
    height: 10%;
  }
}